import React from 'react';
import {Button, Col, Form, FormGroup, Modal, Row} from "react-bootstrap";
import ResourceComponent from "../../../components/ResourceComponent";
import ColorCheckbox from "../../Scheduler/ColorCheckbox";
import './ExportToKMZModal.scss'
import PropTypes from "prop-types";

class ExportToKMZModal extends ResourceComponent {

    state = {
        resource: {
            asset_number: true,
            color: true,
            plant_name: true,
            plant_color: true,
            plant_dbh: true,
            plant_count: true,
            proposal_services: true,
            proposal_services_service_name: true,
            proposal_services_service_total: true,
            proposal_services_service_status_name: true,
            proposal_services_service_proposed: true,
            proposal_services_completed_at: true,
            proposal_services_person_name: true,
            description: true,
            note: true,
            current_stats: true,
            current_stats_condition: true,
            current_stats_dbh: true,
            current_stats_height: true,
            current_stats_width: true,
            current_stats_stem_count: true,
            current_stats_review_date: true,
            current_stats_factors: true,
            hazards: true,
            images: true
        },
        loading: false
    };

    onExport = () => {
        this.setState({loading: true});
        this.props.exportToKMZ(this.state.resource, this.props.filename);
    }

    render() {
        const {onClose, filename} = this.props;
        const {resource, loading} = this.state;

        return (
            <Modal
                bsSize="small"
                animation={false}
                show={true}
                onHide={onClose}
                backdrop="static"
                className="export-to-kmz-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Export to KMZ: <b>{filename}</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Choose which data should be exported</h4>
                    <br/>
                    <Form onSubmit={() => null}>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <ColorCheckbox value={resource.asset_number}
                                                   label="Asset Number"
                                                   disabled={false}
                                                   onChange={this.selectCheckboxAttr('asset_number')}/>
                                </FormGroup>
                                <FormGroup>
                                    <ColorCheckbox value={resource.color}
                                                   label="Color"
                                                   disabled={false}
                                                   onChange={this.selectCheckboxAttr('color')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <ColorCheckbox value={resource.plant_name}
                                                   label="Plant Name"
                                                   disabled={false}
                                                   onChange={this.selectCheckboxAttr('plant_name')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <ColorCheckbox value={resource.plant_color}
                                                   label="Plant Color"
                                                   disabled={false}
                                                   onChange={this.selectCheckboxAttr('plant_color')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <ColorCheckbox value={resource.plant_dbh}
                                                   label="Plant DBH"
                                                   disabled={false}
                                                   onChange={this.selectCheckboxAttr('plant_dbh')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <ColorCheckbox value={resource.plant_count}
                                                   label="Plant Count"
                                                   disabled={false}
                                                   onChange={this.selectCheckboxAttr('plant_count')}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <ColorCheckbox value={resource.description}
                                                   label="Description"
                                                   disabled={false}
                                                   onChange={this.selectCheckboxAttr('description')}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <ColorCheckbox value={resource.note}
                                                   label="Note"
                                                   disabled={false}
                                                   onChange={this.selectCheckboxAttr('note')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <ColorCheckbox value={resource.hazards}
                                                   label="Hazards"
                                                   disabled={false}
                                                   onChange={this.selectCheckboxAttr('hazards')}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <ColorCheckbox value={resource.images}
                                                   label="Images"
                                                   disabled={false}
                                                   onChange={this.selectCheckboxAttr('images')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <ColorCheckbox value={resource.proposal_services}
                                                   label="Proposal Services"
                                                   disabled={false}
                                                   onChange={this.selectCheckboxAttr('proposal_services')}
                                    />
                                </FormGroup>
                                {resource.proposal_services && <div className="nested-options">

                                    <FormGroup>
                                        <ColorCheckbox value={resource.proposal_services_service_name}
                                                       label="Name"
                                                       disabled={false}
                                                       onChange={this.selectCheckboxAttr('proposal_services_service_name')}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ColorCheckbox value={resource.proposal_services_service_total}
                                                       label="Total Price"
                                                       disabled={false}
                                                       onChange={this.selectCheckboxAttr('proposal_services_service_total')}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ColorCheckbox value={resource.proposal_services_service_status_name}
                                                       label="Status"
                                                       disabled={false}
                                                       onChange={this.selectCheckboxAttr('proposal_services_service_status_name')}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ColorCheckbox value={resource.proposal_services_service_proposed}
                                                       label="Proposed Date"
                                                       disabled={false}
                                                       onChange={this.selectCheckboxAttr('proposal_services_service_proposed')}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ColorCheckbox value={resource.proposal_services_completed_at}
                                                       label="Completed At"
                                                       disabled={false}
                                                       onChange={this.selectCheckboxAttr('proposal_services_completed_at')}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ColorCheckbox value={resource.proposal_services_person_name}
                                                       label="Person Name"
                                                       disabled={false}
                                                       onChange={this.selectCheckboxAttr('proposal_services_person_name')}
                                        />
                                    </FormGroup>
                                </div>}
                            </Col>
                            <Col md={4}>

                                <FormGroup>
                                    <ColorCheckbox value={resource.current_stats}
                                                   label="Current Stats"
                                                   disabled={false}
                                                   onChange={this.selectCheckboxAttr('current_stats')}
                                    />
                                </FormGroup>
                                {resource.current_stats && <div className="nested-options">
                                    <FormGroup>
                                        <ColorCheckbox value={resource.current_stats_condition}
                                                       label="Condition"
                                                       disabled={false}
                                                       onChange={this.selectCheckboxAttr('current_stats_condition')}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ColorCheckbox value={resource.current_stats_dbh}
                                                       label="DBH"
                                                       disabled={false}
                                                       onChange={this.selectCheckboxAttr('current_stats_dbh')}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ColorCheckbox value={resource.current_stats_height}
                                                       label="Height"
                                                       disabled={false}
                                                       onChange={this.selectCheckboxAttr('current_stats_height')}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ColorCheckbox value={resource.current_stats_width}
                                                       label="Width"
                                                       disabled={false}
                                                       onChange={this.selectCheckboxAttr('current_stats_width')}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ColorCheckbox value={resource.current_stats_stem_count}
                                                       label="Stem Count"
                                                       disabled={false}
                                                       onChange={this.selectCheckboxAttr('current_stats_stem_count')}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ColorCheckbox value={resource.current_stats_review_date}
                                                       label="Review Date"
                                                       disabled={false}
                                                       onChange={this.selectCheckboxAttr('current_stats_review_date')}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ColorCheckbox value={resource.current_stats_factors}
                                                       label="Factors"
                                                       disabled={false}
                                                       onChange={this.selectCheckboxAttr('current_stats_factors')}
                                        />
                                    </FormGroup>
                                </div>}
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        bsSize="small"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        bsSize="small"
                        bsStyle="success"
                        onClick={this.onExport}
                        disabled={loading}
                    >
                        Export
                    </Button>
                </Modal.Footer>
            </Modal>
        )
            ;
    }
}

ExportToKMZModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    exportToKMZ: PropTypes.func.isRequired,
    filename: PropTypes.string.isRequired,
};

export default ExportToKMZModal;
