import React, {useEffect, useState} from 'react';
import {
    Checkbox,
    ControlLabel,
    FormControl,
    FormGroup,
    InputGroup,
    Modal,
    Radio,
    Well
} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWindowClose} from "@fortawesome/free-solid-svg-icons";
import "./PrintOptionsHistoryModal.scss"
import moment from "moment";
import {defaultDateTimeFormat} from "../../common/commonHandlers";
import {connect} from "react-redux";
import {fetchOptions} from "./actions";
import {bindActionCreators} from "redux";
import MDSpinner from "react-md-spinner";
import InputGroupAddon from "react-bootstrap/lib/InputGroupAddon";

const positionsAttachments = [
    {label: 'Before Services', value: 'before_services'},
    {label: 'After Services', value: 'after_services'},
    {label: 'Before Images', value: 'before_images'},
    {label: 'After Images', value: 'after_images'},
]

function findDifferentKeys(oldValues, newValues) {
    const differentKeys = [];

    if (!oldValues || !newValues) {
        return [];
    }

    for (const key in oldValues) {
        if (oldValues.hasOwnProperty(key)) {
            if (newValues.hasOwnProperty(key)) {
                if (oldValues[key] !== newValues[key]) {
                    differentKeys.push(key);
                }
            } else {
                differentKeys.push(key);
            }
        }
    }

    for (const key in newValues) {
        if (newValues.hasOwnProperty(key)) {
            if (oldValues.hasOwnProperty(key)) {
                if (newValues[key] !== oldValues[key]) {
                    differentKeys.push(key);
                }
            } else {
                differentKeys.push(key);
            }
        }
    }

    for (const key in newValues) {
        if (newValues.hasOwnProperty(key) && !oldValues.hasOwnProperty(key)) {
            if (newValues[key] === true) {
                differentKeys.push(key);
            }
        }
    }

    const onlyUnique = (value, index, array) => {
        return array.indexOf(value) === index;
    }

    return differentKeys.filter(onlyUnique);
}

const PrintOptionsHistoryModal = ({isOpen, close, proposal, invoice, actions}) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(1);

    useEffect(() => {
        setLoading(true);
        if(proposal) {
            actions.fetchOptions(proposal.id, page, perPage, false, (d) => {
                setOptions(d.options || [])
                setTotal(d.total || 1)
                setLoading(false);
            })
        } else if (invoice) {
            actions.fetchOptions(invoice.id, page, perPage, true, (d) => {
                setOptions(d.options || [])
                setTotal(d.total || 1)
                setLoading(false);
            })
        }

    }, [page, perPage]);


    return <Modal
        show={isOpen}
        onHide={() => close()}
        className="options-history-modal">
        <Modal.Header>
            <Modal.Title>
                <div className='oh-modal-header'>
                    <div>
                        {proposal && <p className='font16'>Print Options History (Proposal #{proposal.proposal_no})</p>}
                        {invoice && <p className='font16'>Print Options History (Invoice #{invoice.number})</p>}
                    </div>
                    <div className='oh-modal-header__btns'>
                        <FontAwesomeIcon icon={faWindowClose} onClick={() => close()}/>
                    </div>
                </div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {loading && <div className="loader history-loader">
                <MDSpinner size={100} borderSize={8}/>
            </div>}
            <div className="rows-wrapper">
                {!loading && options.map(row => <HistoryEntryRow isProposal={proposal} row={row} />)}
            </div>
        </Modal.Body>
        <Modal.Footer>
            <div className="footer-wrapper">
                <div className="btn-group dropup">
                    <button className="btn btn-info dropdown-toggle" type="button" id="dropdownMenu2"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span>{perPage}</span>
                        <span className="ml-6 caret"/>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        {[5, 10, 15, 20, 25].map((v) =>
                            <li className='pointer'>
                                <a
                                    className='table-pagination dropdown-item'
                                    onClick={() => {
                                        setPage(1);
                                        setPerPage(v);
                                    }}
                                >{v}</a>
                            </li>
                        )}
                    </ul>
                </div>
                <Pagination currentPage={page} total={total} perPage={perPage} onPageChange={setPage}/>
            </div>
        </Modal.Footer>
    </Modal>
}

const Pagination = ({ currentPage, total, perPage, onPageChange }) => {
    const maxPagesToShow = 3;
    const pageNumbers = [];
    const totalPages = Math.ceil(total / perPage);

    // Calculate the range of pages to display
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = startPage + maxPagesToShow - 1;

    if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="history-pagination">
            <div
                onClick={() => currentPage - 1 >= 1 && onPageChange(currentPage - 1)}
                className={currentPage === 1 ? 'button arrow inactive disabled' : 'button arrow'}
            >
                &laquo;
            </div>
            {pageNumbers.map((pageNumber) => (
                <div
                    key={pageNumber}
                    className={pageNumber === currentPage ? 'button page active' : 'button page'}
                    onClick={() => onPageChange(pageNumber)}
                >
                    {pageNumber}
                </div>
            ))}
            <div
                onClick={() => currentPage + 1 <= totalPages && onPageChange(currentPage + 1)}
                className={currentPage === totalPages ? 'button arrow inactive disabled' : 'button arrow'}
            >
                &raquo;
            </div>
        </div>
    );
};

const HistoryEntryRow = ({row, isProposal}) => {

    const oldValue = row.old_value ? JSON.parse(row.old_value) : null;
    const newValue = row.new_value ? JSON.parse(row.new_value) : null;

    const differences = findDifferentKeys(oldValue, newValue);
    
    const getActivityString = () => {
        if(row.activity === "new_proposal"){
            return "New proposal with default values";
        }
        if(row.activity === "new_invoice"){
            return "New invoice with default values";
        }
        if(row.activity === "updated_field") {
            return `Changed value of field`;
        }
        if(row.activity === "reset_options") {
            return `Set options to default value`;
        }
    }

    return <div className="history-modal-row">
        <div>
            <span className="medium-label">Edited By: <span className="bold">{row.edited_by}</span> At <span className="bold">{moment(row.created_at).format(defaultDateTimeFormat)}</span></span>
        </div>
        <div>
            <span className="medium-label">Activity: <span className="bold">{getActivityString()}</span></span>
        </div>
        {row.activity !== "new_proposal" && <div className="flex-col-row">
            <span className="big-label bold">Old Values</span>
            {oldValue ? <CheckboxGroup id={"old_"+row.id} isProposal={isProposal} printOptions={oldValue} differences={differences}/> : <span>No Old Values</span>}
        </div>}
        <div className="flex-col-row">
            <span className="big-label bold">New Values</span>
            {newValue ? <CheckboxGroup id={"new_"+row.id} isProposal={isProposal} printOptions={newValue} differences={differences}/> : <span>No New Values</span>}
        </div>
    </div>
}

const CheckboxGroup = ({id, isProposal, printOptions, differences}) => {
    const isDifferent = (key) => differences.includes(key);

    return <div style={{display: "flex", flexDirection: "row", gap: "10px", justifyContent: "space-between"}}>
            <Well bsSize="small">
                <h5>Prices</h5>
                <FormGroup>
                    {isProposal && <Checkbox
                        name="proposalTotal"
                        checked={printOptions.proposalTotal || false}
                        inline
                        style={{color: isDifferent("proposalTotal") ? "red" : "unset"}}
                    >
                        Proposal Total
                    </Checkbox>}
                    {!isProposal && <Checkbox
                        name="proposalTotal"
                        checked={printOptions.tax || false}
                        inline
                        style={{color: isDifferent("proposalTotal") ? "red" : "unset"}}
                    >
                        Tax
                    </Checkbox>}
                    <br/>
                    <Checkbox
                        name="servicePrices"
                        checked={printOptions.servicePrices || false}
                        inline
                        style={{color: isDifferent("servicePrices") ? "red" : "unset"}}
                    >
                        Subtotals
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="balance"
                        checked={printOptions.balance || false}
                        inline
                        style={{color: isDifferent("balance") ? "red" : "unset"}}
                    >
                        Balance
                    </Checkbox>
                </FormGroup>
            </Well>

        <Well bsSize="small">
            <h5>Notes</h5>
            <FormGroup>
                <Checkbox
                    name="assetNotes"
                    checked={printOptions.assetNotes || false}
                    inline
                    style={{color: isDifferent("assetNotes") ? "red" : "unset"}}
                >
                        Asset Notes
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="proposalServiceNotes"
                        checked={printOptions.proposalServiceNotes || false}
                        inline
                        style={{color: isDifferent("proposalServiceNotes") ? "red" : "unset"}}
                    >
                        Service Notes
                    </Checkbox>
                    <br/>
                    {isProposal && <> <Checkbox
                        name="proposalServiceStatus"
                        checked={printOptions.proposalServiceStatus || false}
                        inline
                        style={{color: isDifferent("proposalServiceStatus") ? "red" : "unset"}}
                    >
                        Service Status
                    </Checkbox>
                    <br/> </>}
                    <FormGroup>
                        <Checkbox
                            name="proposalCustomerNote"
                            checked={printOptions.proposalCustomerNote || false}
                            inline
                            style={{color: isDifferent("proposalCustomerNote") ? "red" : "unset"}}
                        >
                            <div>
                                <div>Proposal Note</div>
                                <Checkbox
                                    name="notesAfterServices"
                                    checked={printOptions.notesAfterServices || false}
                                    disabled={!printOptions.proposalCustomerNote}
                                    inline
                                    style={{color: isDifferent("notesAfterServices") ? "red" : "unset"}}
                                >
                                    After services
                                </Checkbox>
                            </div>
                            <div>
                                <Checkbox
                                    name="notesBeforeServices"
                                    checked={printOptions.notesBeforeServices || false}
                                    disabled={!printOptions.proposalCustomerNote}
                                    inline
                                    style={{color: isDifferent("notesBeforeServices") ? "red" : "unset"}}
                                >
                                    Before services
                                </Checkbox>
                            </div>
                        </Checkbox>
                    </FormGroup>
                </FormGroup>
            </Well>

        {isProposal && <Well bsSize="small">
                <h5>Attachments</h5>
                <FormGroup>
                    <Checkbox
                        name="customerAttachments"
                        checked={printOptions.customerAttachments || false}
                        inline
                        style={{color: isDifferent("positionCustomerAttachments") || isDifferent("customerAttachments") ? "red" : "unset"}}
                    >
                        <div>User's Photos</div>
                        {positionsAttachments.map((position) => (
                            <div>
                                <Radio
                                    name={`positionCustomerAttachments--${id}`}
                                    checked={printOptions.positionCustomerAttachments === position.value}
                                    disabled={!printOptions.customerAttachments}
                                    value={position.value}
                                    inline
                                >
                                    {position.label}
                                </Radio>
                            </div>
                        ))}
                    </Checkbox>
                </FormGroup>
                <FormGroup>
                    <Checkbox
                        name="sitesAttachments"
                        checked={printOptions.sitesAttachments || false}
                        inline
                        style={{color: isDifferent("positionSitesAttachments") || isDifferent("sitesAttachments") ? "red" : "unset"}}
                    >
                        <div>Sites Photos</div>
                        {positionsAttachments.map((position) => (
                            <div>
                                <Radio
                                    name={`positionSitesAttachments--${id}`}
                                    checked={printOptions.positionSitesAttachments === position.value}
                                    disabled={!printOptions.sitesAttachments}
                                    value={position.value}
                                    inline
                                >
                                    {position.label}
                                </Radio>
                            </div>
                        ))}
                    </Checkbox>
                </FormGroup>
            </Well>}

            <Well bsSize="small">
                <div>

                </div>
                <h5>Maps</h5>
                <FormGroup>
                    <Checkbox
                        name="maps"
                        checked={printOptions.maps || false}
                        inline
                        style={{color: isDifferent("maps") ? "red" : "unset"}}
                    >
                        <div>
                            <div>Maps</div>
                            <Checkbox
                                name="fitBounds"
                                checked={printOptions.fitBounds || false}
                                inline
                                style={{color: isDifferent("fitBounds") ? "red" : "unset"}}
                            >
                                Fit Bounds
                            </Checkbox>
                        </div>
                    </Checkbox>
                    {/*{proposal.site_maps.map((sitemap, i) => (*/}
                    {/*        <div key={sitemap.id}>*/}
                    {/*            <Checkbox*/}
                    {/*                name={`maps_${sitemap.id}`}*/}
                    {/*                checked={printOptions[`maps_${sitemap.id}`]}*/}
                    {/*                onChange={this.handleFormChange}*/}
                    {/*                inline*/}
                    {/*            >*/}
                    {/*                Sitemap {i + 1}*/}
                    {/*            </Checkbox>*/}
                    {/*        </div>*/}
                    {/*    )*/}
                    {/*)}*/}
                </FormGroup>
            </Well>

            <Well bsSize="small">
                <h5>Assets</h5>
                <FormGroup>
                    <Checkbox
                        name="largeAssetPhotos"
                        checked={printOptions.largeAssetPhotos || false}
                        inline
                        style={{color: isDifferent("largeAssetPhotos") ? "red" : "unset"}}
                    >
                        Asset Photos
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="assetPhotosCustomerView"
                        checked={printOptions.assetPhotosCustomerView || false}
                        inline
                        style={{color: isDifferent("assetPhotosCustomerView") ? "red" : "unset"}}
                    >
                        Asset Photos in customer view
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="locationDescription"
                        checked={printOptions.locationDescription || false}
                        inline
                        style={{color: isDifferent("locationDescription") ? "red" : "unset"}}
                    >
                        Location Description
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="dbh"
                        checked={printOptions.dbh || false}
                        inline
                        style={{color: isDifferent("dbh") ? "red" : "unset"}}
                    >
                        DBH
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="condition"
                        checked={printOptions.condition || false}
                        inline
                        style={{color: isDifferent("condition") ? "red" : "unset"}}
                    >
                        Condition
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="lat"
                        checked={printOptions.lat || false}
                        inline
                        style={{color: isDifferent("lat") ? "red" : "unset"}}
                    >
                        Latitude
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="lng"
                        checked={printOptions.lng || false}
                        inline
                        style={{color: isDifferent("lng") ? "red" : "unset"}}
                    >
                        Longitude
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="height"
                        checked={printOptions.height || false}
                        inline
                        style={{color: isDifferent("height") ? "red" : "unset"}}
                    >
                        Height
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="width"
                        checked={printOptions.width || false}
                        inline
                        style={{color: isDifferent("width") ? "red" : "unset"}}
                    >
                        Width
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="stems"
                        checked={printOptions.stems || false}
                        inline
                        style={{color: isDifferent("stems") ? "red" : "unset"}}
                    >
                        Stems
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="factors"
                        checked={printOptions.factors || false}
                        inline
                        style={{color: isDifferent("factors") ? "red" : "unset"}}
                    >
                        Factors
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="wrapVertically"
                        checked={printOptions.wrapVertically || false}
                        inline
                        style={{color: isDifferent("wrapVertically") ? "red" : "unset"}}
                    >
                        Wrap Vertically
                    </Checkbox>
                </FormGroup>
            </Well>

            <Well bsSize="small">
                <h5>Services</h5>
                <FormGroup>
                    <Checkbox
                        name="serviceDateRanges"
                        checked={printOptions.serviceDateRanges || false}
                        inline
                        style={{color: isDifferent("serviceDateRanges") ? "red" : "unset"}}
                    >
                        Service Date Ranges
                    </Checkbox>
                    <br/>
                    {isProposal && <> <Checkbox
                        name="servicesNotOffered"
                        checked={printOptions.servicesNotOffered || false}
                        inline
                        style={{color: isDifferent("servicesNotOffered") ? "red" : "unset"}}
                    >
                        Not Offered
                    </Checkbox>
                    <br/> </>}
                    <Checkbox
                        name="servicePriorities"
                        checked={printOptions.servicePriorities || false}
                        inline
                        style={{color: isDifferent("servicePriorities") ? "red" : "unset"}}
                    >
                        Priorities
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="thumbAssetPhotos"
                        checked={printOptions.thumbAssetPhotos || false}
                        inline
                        style={{color: isDifferent("thumbAssetPhotos") ? "red" : "unset"}}
                    >
                        Thumbnail Asset Photos
                    </Checkbox>
                </FormGroup>
            </Well>

            <Well bsSize="small">
                <h5>Charts</h5>
                <FormGroup>
                    <Checkbox
                        name="assetSummary"
                        checked={printOptions.assetSummary || false}
                        inline
                        style={{color: isDifferent("assetSummary") ? "red" : "unset"}}
                    >
                        Asset Summary
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="serviceType"
                        checked={printOptions.serviceType || false}
                        inline
                        style={{color: isDifferent("serviceType") ? "red" : "unset"}}
                    >
                        Service Type
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="serviceSummary"
                        checked={printOptions.serviceSummary || false}
                        inline
                        style={{color: isDifferent("serviceSummary") ? "red" : "unset"}}
                    >
                        Service Summary
                    </Checkbox>
                    <br/>
                </FormGroup>
            </Well>

        {isProposal ? <Well bsSize="small">
                <h5>Options</h5>
                <FormGroup>
                    <Checkbox
                        name="arboristInfo"
                        checked={printOptions.arboristInfo || false}
                        inline
                        style={{color: isDifferent("arboristInfo") ? "red" : "unset"}}
                    >
                        Arborist Info
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="tos"
                        checked={printOptions.tos || false}
                        inline
                        style={{color: isDifferent("tos") ? "red" : "unset"}}
                    >
                        Terms
                    </Checkbox>

                    <br/>
                    <Checkbox
                        name="proposal_footer"
                        checked={printOptions.proposal_footer || false}
                        inline
                        style={{color: isDifferent("proposal_footer") ? "red" : "unset"}}
                    >
                        Footer
                    </Checkbox>

                    <br/>
                    <Checkbox
                        name="assInline"
                        checked={printOptions.assInline || false}
                        inline
                        style={{color: isDifferent("assInline") ? "red" : "unset"}}
                    >
                        Assets Inline
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="showLogo"
                        checked={printOptions.showLogo || false}
                        inline
                        style={{color: isDifferent("showLogo") ? "red" : "unset"}}
                    >
                        Show Company Logo
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="showPageNumbers"
                        checked={printOptions.showPageNumbers || false}
                        inline
                        style={{color: isDifferent("showPageNumbers") ? "red" : "unset"}}
                    >
                        Show Page Numbers
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="showCompanyAddress"
                        checked={printOptions.showCompanyAddress || false}
                        inline
                        style={{color: isDifferent("showCompanyAddress") ? "red" : "unset"}}
                    >
                        Show Company Address
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="showSignature"
                        checked={printOptions.showSignature || false}
                        inline
                        style={{color: isDifferent("showSignature") ? "red" : "unset"}}
                    >
                        Show Signature
                    </Checkbox>
                    <br/>
                    <Checkbox
                        name="showSignatureData"
                        checked={printOptions.showSignatureData || false}
                        inline
                        style={{color: isDifferent("showSignatureData") ? "red" : "unset"}}
                    >
                        Show Signature Data
                    </Checkbox>
                </FormGroup>
            </Well> : <Well bsSize="small">
            <h5>Options</h5>
            <FormGroup>
                <Checkbox
                    name="invoiceSiteName"
                    checked={printOptions.invoiceSiteName || false}
                    inline
                    style={{color: isDifferent("invoiceSiteName") ? "red" : "unset"}}
                >
                    Invoice Site Name
                </Checkbox>
                <br/>
                <Checkbox
                    name="invoiceSiteAddress"
                    checked={printOptions.invoiceSiteAddress || false}
                    inline
                    style={{color: isDifferent("invoiceSiteAddress") ? "red" : "unset"}}
                >
                    Invoice Site Address
                </Checkbox>
                <br/>
                <Checkbox
                    name="arboristInfo"
                    checked={printOptions.arboristInfo || false}
                    inline
                    style={{color: isDifferent("arboristInfo") ? "red" : "unset"}}
                >
                    Arborist Info
                </Checkbox>
                <br/>
                <Checkbox
                    name="assInline"
                    checked={printOptions.assInline || false}
                    inline
                    style={{color: isDifferent("assInline") ? "red" : "unset"}}
                >
                    Assets Inline
                </Checkbox>
                <br/>
                <Checkbox
                    name="purchaseOrder"
                    checked={printOptions.purchaseOrder || false}
                    inline
                    style={{color: isDifferent("purchaseOrder") ? "red" : "unset"}}
                >
                    PO Number
                </Checkbox>
                <br/>
                <Checkbox
                    name="showLogo"
                    checked={printOptions?.showLogo || false}
                    inline
                    style={{color: isDifferent("showLogo") ? "red" : "unset"}}
                >
                    Show Company Logo
                </Checkbox>
                <br/>
                <Checkbox
                    name="showPageNumbers"
                    checked={printOptions.showPageNumbers || false}
                    inline
                    style={{color: isDifferent("showPageNumbers") ? "red" : "unset"}}
                >
                    Show Page Numbers
                </Checkbox>
                <br/>
                <Checkbox
                    name="showCompanyAddress"
                    checked={printOptions.showCompanyAddress || false}
                    inline
                    style={{color: isDifferent("showCompanyAddress") ? "red" : "unset"}}
                >
                    Show Company Address
                </Checkbox>
                <br/>
                <Checkbox
                    name="showPaidImage"
                    checked={printOptions.showPaidImage || false}
                    inline
                    style={{color: isDifferent("showPaidImage") ? "red" : "unset"}}
                >
                    Show Paid Image
                </Checkbox>
                <br/>
                <Checkbox
                    name="showInvoicePayments"
                    checked={printOptions.showInvoicePayments || false}
                    inline
                    style={{color: isDifferent("showInvoicePayments") ? "red" : "unset"}}
                >
                    Show Invoice Payments
                </Checkbox>
                <br/>
                <Checkbox
                    name="showTerms"
                    checked={printOptions.showTerms || false}
                    inline
                    style={{color: isDifferent("showTerms") ? "red" : "unset"}}
                >
                    Show Terms and Conditions
                </Checkbox>
            </FormGroup>
        </Well>}

            <Well bsSize="small">
                <h5>Map options</h5>
                <div className='d-flex'>
                    <div className='columnDirection full-width'>
                        <FormGroup>
                            <Checkbox
                                name="atTheEnd"
                                checked={printOptions.atTheEnd || false}
                                inline
                                style={{color: isDifferent("atTheEnd") ? "red" : "unset"}}
                            >
                                Show at the end
                            </Checkbox>
                        </FormGroup>
                        <FormGroup className='space-between'>
                            <ControlLabel className='mr-10'>
                                Margin Top
                            </ControlLabel>
                            <FormControl
                                name="marginTop"
                                type="text"
                                placeholder="Top"
                                style={{maxWidth: 120, backgroundColor: isDifferent("marginTop") ? "#ff00005c" : ""}}
                                value={printOptions.marginTop}
                                disabled
                            />
                        </FormGroup>
                        <FormGroup className='space-between'>
                            <ControlLabel className='mr-10'>
                                Margin Bottom
                            </ControlLabel>
                            <FormControl
                                name="marginBottom"
                                type="text"
                                placeholder="Bottom"
                                style={{maxWidth: 120, backgroundColor: isDifferent("marginBottom") ? "#ff00005c" : ""}}
                                value={printOptions.marginBottom}
                                disabled
                            />
                        </FormGroup>
                    </div>
                </div>
                <h5>Font options</h5>
                {['Header', 'Content', 'Footer'].map((label) => {
                    const optionName = label.toLowerCase() + "FontSize";
                    return (<FormGroup>
                        <div className='space-between'>
                            <ControlLabel className='flex-basis-30'>
                                {label}
                            </ControlLabel>
                            <InputGroup className='flex-basis-70'>
                                <FormControl
                                    name={optionName}
                                    type="number"
                                    step={1}
                                    min={13}
                                    max={28}
                                    disabled
                                    style={{backgroundColor: isDifferent(optionName) ? "#ff00005c" : ""}}
                                    value={printOptions[optionName] || ""}
                                />
                                <InputGroupAddon style={{backgroundColor: isDifferent(optionName) ? "#ff00005c" : ""}}>
                                    px
                                </InputGroupAddon>
                            </InputGroup>
                        </div>
                    </FormGroup>)
                })}
            </Well>
            {printOptions.thumbAssetPhotos &&
                <Well bsSize="small">
                    <h5>Thumbnail options</h5>
                    <div className='d-flex'>
                        <div className='columnDirection full-width'>
                            <FormGroup>
                                <Checkbox
                                    name="wrapHorizontally"
                                    checked={printOptions.wrapHorizontally || false}
                                    inline
                                    style={{color: isDifferent("wrapHorizontally") ? "red" : "unset"}}
                                >
                                    Wrap Horizontally
                                </Checkbox>
                            </FormGroup>
                            <FormGroup className='space-between'>
                                <ControlLabel className='mr-10'>
                                    Size
                                </ControlLabel>
                                <FormControl
                                    name="thumbnailSize"
                                    type="text"
                                    placeholder="Size"
                                    style={{maxWidth: 120}}
                                    value={printOptions.thumbnailSize || 180}
                                />
                            </FormGroup>
                        </div>
                    </div>
                </Well>}
    </div>

}

const mapStateToProps = (state) => {
    return {
        state: state
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({fetchOptions}, dispatch)
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(PrintOptionsHistoryModal);