import React from "react"
import MasterDetail from "../../../common/MasterDetail";
import {Col, ControlLabel, FormControl, FormGroup, Row} from "react-bootstrap";
import {merge, optionsFor, select, updateExternalLinks} from "../../../common/commonHandlers";
import {bindActionCreators} from 'redux'
import createApiService from "../../../common/clientActionsBuilder";
import {connect} from "react-redux";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {ContentState, EditorState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import ResourceComponent from "../../../components/ResourceComponent";
import Select from "react-select"
import {Jodit} from 'jodit';
import 'jodit/build/jodit.min.css';
import JoditEditor from "jodit-react";
import $ from "jquery";
import EmailEditorPreview from './EmailEditorPreview'

import * as EmailTemplatesActions from './EmailTemplatesApi'
import {JODIT_BUTTONS} from "../../../constants";
import {MaterialSwitch} from "../../../components/MaterialSwitch/MaterialSwitch";
import {Editor} from '@tinymce/tinymce-react';
import config from "../../../config/environment"
import {getClient, saveEditorSettings} from "../../../components/email/EmailEditorApi";
import _ from "lodash";


const api = createApiService('email_templates', 'email_template', 'Email template');
const actions = {...api, ...EmailTemplatesActions}
const isMobile = window.screen.width < 768;

// "self.validate_shortcodes_for": "accessors"
const shortcodeOptions = {
    "payment": ['payment'],
    "payment failed": ['payment_failed'],
    "payment deposit": ['payment_deposit'],
    "invoice": ['invoice'],
    "invoice paid": ['invoice', 'invoice paid'],
    "issue": ['issue'],
    "proposal": ['proposal'],
    "sticky": ['sticky', 'proposal'],
    "scheduler event": ['scheduler_event', 'work_order', 'lead'],
    "email": ['email'],
    "bulk": ['proposal', 'work_order', 'scheduler_event'],
    "automatic email": ['email_automatic'],
    "fleet payment email": ['truck'],
    "briefing": ['user'],
    "lead duplicate": ['lead_duplicate'],
    "service priority": ['priority', 'recipient'],
    "service completed": ['ps'],
    "backlog": ['proposal', 'proposal_services'],
    "job completed": ['job'],
    "other (No validation)": []
}

const validateShortcodesOptions = Object.keys(shortcodeOptions).map(e => ({label: e, value: e}));

class EmailTemplates extends ResourceComponent {
    defaultValues = {name: '', from: '', reply_to: '', bcc: '', subject: '', message: ''};
    state = {};

    constructor(props) {
        super(props);
        this.state = {
            resource: {
                message: '',
            },
            isPreviewModalOpened: false,
            advancedView: false,
            config: this.basicConfig,
            showHtmlModal: false,
            isJodit: false,
            initialValue: "",
            shortcodes: [],
            shortcodesLoading: true
        }
    }

    editorRef = React.createRef();

    basicConfig = {
        readonly: false,
        toolbarInline: true,
        popup: {
            cells: [...JODIT_BUTTONS.basic.popupButtons],
        },
        height: 500,
        width: isMobile ? (window.screen.width - 30) : null,
        enter: 'div',
        extraButtons: [{
            name: 'preview',
            exec: () => this.setState({isPreviewModalOpened: true})
        }],
        colorPickerDefaultTab: "color",
        extraPlugins: ['tooltip'],
        showTooltipDelay: 0,
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        defaultActionOnPaste: "insert_only_text",
        ...JODIT_BUTTONS.basic
    }

    setRef = jodit => this.jodit = jodit;

    updateResources = resources => {
        this.setState({resources})
    };

    updateJoditConfig = () => {
        const {advancedView} = this.state;
        const advancedConfig = {
            readonly: false,
            enter: 'div',
            disablePlugins: ["preview"],
            buttons: [
                ...Jodit.defaultOptions.buttons.filter(b => b.group !== "source" && b.group !== "preview"),
                {
                    name: 'source',
                    exec: (editor) => {
                        editor.selection.clear();
                        editor.selection.remove();
                        setTimeout(() => {
                            editor.setMode(editor.mode === 1 ? 2 : 1)
                        }, 100)
                    }
                },
                {
                    name: 'preview',
                    exec: () => this.setState({isPreviewModalOpened: true})
                },
            ],
            popup: {
                cells: [
                    ...JODIT_BUTTONS.advanced.popupButtons,
                    Jodit.defaultOptions.popup.cells[6],
                    Jodit.defaultOptions.popup.cells[7],
                    Jodit.defaultOptions.popup.cells[8]
                ]
            },
            height: 500,
            width: isMobile ? (window.screen.width - 30) : null,
            colorPickerDefaultTab: "color",
            extraPlugins: ['tooltip'],
            showTooltipDelay: 0,
            askBeforePasteHTML: this.state.showHtmlModal,
            askBeforePasteFromWord: false,
            defaultActionOnPaste: "insert_only_text",
        };

        this.setState({
            config: advancedView ? advancedConfig : this.basicConfig,
        });
    };

    componentDidMount = () => {
        this.props.actions.load({}, this.updateResources);
        this.props.actions.getTextMessageTemplates(res => {
            this.setState({textMessageTemplateOptions: res})
        })
        this.props.actions.getShortCodes((shortcodes) => {
            this.setState({shortcodes, shortcodesLoading: false})
        })

        if (this.props.clientId) {
            this.props.actions.getClient(this.props.clientId, (res) => {
                const parsed_editor_settings = res.editor_settings
                this.setState({isJodit: parsed_editor_settings?.email_templates === 'jodit'})
            })
        }

        $('body').css('overflow-y', 'scroll');
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.advancedView !== this.state.advancedView || prevState.showHtmlModal !== this.state.showHtmlModal) {
            this.updateJoditConfig();
        }
    }

    onEditorStateChange = (editorState) => {
        let {resource} = this.state;
        resource.message = editorState;
        this.setState(resource);
        this.setState({client: this.state.client});
    };

    handleSelect = e => {
        this.setState({resource: merge(e.resource, this.defaultValues), initialValue: e.resource?.message});
        const contentBlock = htmlToDraft(e.resource.message || '');
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.setState({editorState})
    };

    reloadResources = resource => {
        this.setState({resource});
        this.componentDidMount();
    };

    setInnerHTML = (text) => {
        return <div dangerouslySetInnerHTML={{__html: text}}/>
    }

    render() {
        const {
            resource,
            resources,
            isPreviewModalOpened,
            textMessageTemplateOptions,
            advancedView,
            showHtmlModal,
            isJodit,
            shortcodesLoading,
            shortcodes
        } = this.state;
        const options = optionsFor('sticky', 'proposal', 'proposal confirmation auto email', 'proposal decline auto email', 'scheduler_event', 'invoice', 'arborist proposal confirmation', 'arborist message confirmation', 'customer message confirmation', 'issue', 'issue change', 'payment', 'embedded', 'customer payment request', 'arborist payment request', 'payment notification', 'crew message', 'work order completed', 'service completed', 'rejected email', 'subscription expiration', 'subscription created', 'subscription updated', 'subscription deleted', 'subscription paid', 'technician email', 'subscription payment failed', 'backlog proposal services', 'daily briefing', 'service priority', 'invitation', 'request password change', 'charge failed', 'credentials request', 'truck payment', 'lead duplicate', 'job completed', 'customer feedback', 'google review', 'invoice paid', 'download notification', 'checklist reminder', 'deposit', 'deposit notification');

        return (
            resources ? <MasterDetail
                resourceName="Email template"
                resourceNamePlural="Email templates"
                withToggle={true}
                toggleData={{
                    value: isJodit,
                    onChange: () => {
                        this.setState({isJodit: !isJodit});
                        this.props.actions.saveEditorSettings(
                            JSON.stringify({place: "email_templates", value: !isJodit ? "jodit" : "tinyMce"}),
                            this.props.clientId
                        )
                    },
                    labelLeft: "TinyMCE",
                    labelRight: "Jodit"
                }}
                resources={resources}
                resource={resource}
                select={this.handleSelect}
                save={() => this.props.actions.save({
                    ...resource,
                    message: updateExternalLinks("prod", resource.message)
                }, this.reloadResources)}
                delete={() => this.props.actions.remove(resource, x => {
                    this.setState({resource: null}, () => this.props.actions.load({}, this.updateResources));
                })}>
                {resource && <div>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <ControlLabel>
                                    Name
                                </ControlLabel>
                                <FormControl
                                    name="name"
                                    onChange={this.updateResourceAttr}
                                    value={resource.name}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <ControlLabel>Default for</ControlLabel>
                                <Select className="Select" classNamePrefix="select"
                                        value={select(options, resource.default_for)}
                                        isClearable={true}
                                        options={options}
                                        onChange={this.selectResourceAttr('default_for')}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <ControlLabel>
                                    From
                                </ControlLabel>
                                <FormControl
                                    name="from"
                                    placeholder="Default is user email"
                                    onChange={this.updateResourceAttr}
                                    value={resource.from}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <ControlLabel>Text message</ControlLabel>
                                <Select className="Select" classNamePrefix="select"
                                        value={select(textMessageTemplateOptions, resource.text_message_template_id)}
                                        options={textMessageTemplateOptions}
                                        onChange={this.selectResourceAttr('text_message_template_id')}
                                        isClearable
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <ControlLabel>
                                    Reply to
                                </ControlLabel>
                                <FormControl
                                    name="reply_to"
                                    placeholder="Default is user email"
                                    onChange={this.updateResourceAttr}
                                    value={resource.reply_to}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <ControlLabel>Validate shortcodes for</ControlLabel>
                                <Select className="Select" classNamePrefix="select"
                                        value={select(validateShortcodesOptions, resource.validate_shortcodes_for)}
                                        options={validateShortcodesOptions}
                                        onChange={this.selectResourceAttr('validate_shortcodes_for')}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <ControlLabel>
                                    BCC
                                </ControlLabel>
                                <FormControl
                                    name="bcc"
                                    placeholder="Default is user email"
                                    onChange={this.updateResourceAttr}
                                    value={resource.bcc}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <ControlLabel>
                                    Subject
                                </ControlLabel>
                                <FormControl
                                    name="subject"
                                    onChange={this.updateResourceAttr}
                                    value={resource.subject}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <EmailEditorPreview
                            title="Preview mode"
                            show={isPreviewModalOpened}
                            onHide={() => this.setState({isPreviewModalOpened: !isPreviewModalOpened})}
                            closeButton={() => this.setState({isPreviewModalOpened: !isPreviewModalOpened})}
                        >
                            <p className="line_height15">{this.setInnerHTML(resource.message)}</p>
                        </EmailEditorPreview>
                        <Col md={12} className={"jodit-container-col"}>
                            <FormGroup>
                                <div className="space-between">
                                    <ControlLabel>
                                        Message
                                    </ControlLabel>
                                    {isJodit && <div className="d-flex">
                                        {advancedView && <MaterialSwitch
                                            style={{marginRight: 20}}
                                            value={showHtmlModal}
                                            onChange={() => this.setState({showHtmlModal: !showHtmlModal})}
                                            label={"Show HTML Modal"}
                                        />}
                                        <MaterialSwitch
                                            value={advancedView}
                                            onChange={() => this.setState({advancedView: !advancedView})}
                                            label={"Advanced view"}
                                        />
                                    </div>}
                                </div>
                                {isJodit ? <JoditEditor
                                    editorRef={this.setRef}
                                    value={updateExternalLinks(null, resource.message)}
                                    config={this.state.config}
                                    onChange={(newValue) => this.onEditorStateChange(newValue)}
                                    onBlur={(newValue) => this.onEditorStateChange(newValue)}
                                /> : <>
                                    {!shortcodesLoading && <Editor
                                        key={this.state.resource.validate_shortcodes_for}
                                        apiKey={config.tinyMceKey}
                                        onInit={(evt, editor) => this.editorRef.current = editor}
                                        initialValue={this.state.initialValue}
                                        value={updateExternalLinks(null, resource.message)}
                                        onEditorChange={(newValue) => this.onEditorStateChange(newValue)}
                                        init={{
                                            convert_urls: false,
                                            height: 500,
                                            menubar: 'file edit insert view format table tools help',
                                            plugins: [
                                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                            ],
                                            toolbar: 'undo redo | blocks | ' +
                                                'bold italic forecolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat | help | shortcodes',
                                            toolbar_mode: 'wrap',
                                            mobile: {
                                                convert_urls: false,
                                                menubar: true,
                                                toolbar_mode: 'wrap',
                                                plugins: [
                                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                ],
                                            },
                                            setup: (editor) => {
                                                const filterShortcodesKeys = shortcodeOptions[this.state.resource.validate_shortcodes_for]
                                                const items = shortcodes.filter(s => {
                                                    if (this.state.resource.validate_shortcodes_for === "other (No validation)" || !this.state.resource.validate_shortcodes_for) {
                                                        return true
                                                    }
                                                    return filterShortcodesKeys?.includes(s.accessor) || s.accessor === "signature"
                                                }).map(({template_name, accessor, entries}) => {
                                                    const isEntriesArray = Array.isArray(entries);
                                                    const entriesChecked = isEntriesArray ? entries[0] : entries
                                                    const formatted = [];
                                                    Object.keys(entriesChecked).forEach((key) => {
                                                        const value = entriesChecked[key];
                                                        if (Array.isArray(value)) {
                                                            if (value.length === 1) {
                                                                const nestedKeys = Object.keys(value[0])
                                                                nestedKeys.forEach(nestedKey => {
                                                                    if (Array.isArray(value[0][nestedKey])) {
                                                                        const nestedArray = value[0][nestedKey]
                                                                        if (nestedArray.length === 1) {
                                                                            const nestedArrayKeys = Object.keys(nestedArray[0])
                                                                            formatted.push({
                                                                                key: `${key}--${nestedKey}`,
                                                                                type: "array",
                                                                                value: nestedArrayKeys
                                                                            })
                                                                        }
                                                                    }
                                                                })
                                                                formatted.push({key, type: "array", value: nestedKeys})
                                                            } else {
                                                                formatted.push({key, type: "array", value: []})
                                                            }
                                                        } else {
                                                            formatted.push({key, type: "normal"})
                                                        }
                                                    });

                                                    return {
                                                        type: Object.keys(entries).length === 0 ? 'menuitem' : 'nestedmenuitem',
                                                        text: isEntriesArray ? `${template_name} [List]` : template_name,
                                                        getSubmenuItems: () => _.sortBy(formatted, "key").filter(f => !f.key.includes("--")).map(item => {
                                                            if (item.type === "array") {
                                                                const nestedKeys = item.value
                                                                return {
                                                                    type: 'nestedmenuitem',
                                                                    text: `${item.key} [List]`,
                                                                    getSubmenuItems: () => [{
                                                                        type: 'menuitem',
                                                                        text: `${item.key} Wrapper`,
                                                                        onAction: () => editor.insertContent(`
                                                                            {% for ${item.key[0]} in ${accessor}.${item.key} %}
                                                                            {{${item.key[0]}.sample_field <= change this}}
                                                                            {% endfor %}
                                                                        `),
                                                                    }, ...nestedKeys.sort().map(nestedItem => {
                                                                        const deepNested = formatted.find(f => f.key === `${item.key}--${nestedItem}`)
                                                                        if (deepNested) {
                                                                            return {
                                                                                type: 'nestedmenuitem',
                                                                                text: nestedItem,
                                                                                getSubmenuItems: () => [{
                                                                                    type: 'menuitem',
                                                                                    text: `${nestedItem} Wrapper`,
                                                                                    onAction: () => editor.insertContent(`
                                                                                            {% for ${nestedItem[0]} in ${item.key[0]}.${nestedItem} %}
                                                                                            {{${nestedItem[0]}.sample_field <= change this}}
                                                                                            {% endfor %}
                                                                                        `),
                                                                                }, ...deepNested.value.map(deepNestedItem => {
                                                                                    return {
                                                                                        type: 'menuitem',
                                                                                        text: deepNestedItem,
                                                                                        onAction: function () {
                                                                                            editor.insertContent(`{{${nestedItem[0]}.${deepNestedItem}}}`)
                                                                                        }
                                                                                    }
                                                                                })],
                                                                            }
                                                                        }

                                                                        return {
                                                                            type: 'menuitem',
                                                                            text: nestedItem,
                                                                            onAction: function () {
                                                                                editor.insertContent(`{{${item.key[0]}.${nestedItem}}}`)
                                                                            }
                                                                        }
                                                                    })]
                                                                }
                                                            }
                                                            return {
                                                                type: 'menuitem',
                                                                text: item.key,
                                                                onAction: function () {
                                                                    editor.insertContent(`{{${accessor}.${item.key}}}`)
                                                                }
                                                            }
                                                        }),
                                                        onAction: function () {
                                                            editor.insertContent(`{{${accessor}}}`)
                                                        }

                                                    }
                                                })
                                                editor.ui.registry.addMenuButton('shortcodes', {
                                                    text: 'Shortcodes',
                                                    fetch: (callback) => {
                                                        callback(items);
                                                    }
                                                })
                                            }
                                        }}
                                    />}
                                </>}
                            </FormGroup>
                        </Col>
                    </Row></div>
                }
            </MasterDetail> : null);
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({...actions, saveEditorSettings, getClient}, dispatch),
});

export default connect(undefined, mapDispatchToProps)(EmailTemplates)
