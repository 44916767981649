import moment from "moment/moment";
import {dollar} from "../components/Dollars";
import React from "react";
import _ from 'lodash'
import $ from "jquery";
import {dataUpload} from "./upload";
import TextAnswer from "../containers/lists/EquipmentQuestionsFormatters/Answers/TextAnswer";
import CheckboxAnswer from "../containers/lists/EquipmentQuestionsFormatters/Answers/CheckboxAnswer";
import SwitchAnswer from "../containers/lists/EquipmentQuestionsFormatters/Answers/SwitchAnswers";
import DateAnswer from "../containers/lists/EquipmentQuestionsFormatters/Answers/DateAnswer";
import SelectAnswer from "../containers/lists/EquipmentQuestionsFormatters/Answers/SelectAnswer";
import FilesAnswer from "../containers/lists/EquipmentQuestionsFormatters/Answers/FilesAnswer";

const google = window.google;

export function merge(e, defaultState) {
    const result = {};
    Object.keys(e).forEach((key) => result[key] = e[key] || defaultState[key]);
    Object.keys(defaultState).forEach((key) => result[key] = e[key] || defaultState[key]);
    return result;
}

export function optionsFor(...array) {
    return array.map(e => ({label: e, value: e}))
}

export function mapToApi(data) {
    return data.map(x => ({id: x.value, name: x.label}));
}

export function mapForSelect(data) {
    return data.map(x => ({value: x.id, label: x.name}));
}

export function optionsForSelect(array) {
    return array.map(e => ({label: e, value: e}))
}

export function stringifySelect(data) {
    return data.map(x => ({value: x.value.toString(), label: x.label}));
}

export function select(data, value) {
    if (value && data && value.constructor.name === 'Array') {
        return value.map(x => data.find(y => x === y.value || (x && x.id === y.value))).filter(x => x);
    } else {
        return data && data.find(x => x.value === value || (value && value.id === x.value)) || null;
    }
}

export function creatableSelect(data, value) {
    if (value && value.constructor.name === 'Array') {
        return value.map(x => data.find(y => x === y.id)).filter(x => x);
    } else {
        return data && data.find(x => x.id === value) || null;
    }
}

export function addParams(path, params) {
    const entries = params && Object.entries(params);
    if (!params || entries.length === 0) {
        return path;
    }
    const urlParams = new URLSearchParams(entries);
    return `${path}?${urlParams}`;
}

export const defaultDateTimeFormat = "MM/DD/YYYY h:mmA";
export const defaultDateTimeFormatComma = "MM/DD/YYYY, h:mmA";
export const defaultDateFormat = "MM/DD/YYYY";
export const defaultDateTimeFormatShort = "MM/DD/YY h:mmA";
export const defaultDateFormatShort = "MM/DD/YY";
export const defaultTimeFormat = "h:mm A";

export const formatInlineDate = (date) => {
    return (date instanceof moment) ? date.format(defaultDateTimeFormatShort) : moment(date).format(defaultDateTimeFormatShort)
};

export const formatInlineDateOnly = (date) => {
    return (date instanceof moment) ? date.format(defaultDateFormat) : moment(date).format(defaultDateFormat)
};

export const hrefActiveClass = (url) => {
    return window.location.href.includes(url) ? 'active' : '';
};

export const defaultMapCenter = (mapObjects, client) => {
    return mapObjects.length > 0 ? {lat: mapObjects[0].latitude, lng: mapObjects[0].longitude} :
        (client && client.organization_latitude) ? {
            lat: +client.organization_latitude,
            lng: +client.organization_longitude,
        } : {
            lat: 40.0,
            lng: -105.0
        };
};

export const defaultMapParams = (markers, center, zoom, client) => {
    let result = {tilt: 0, defaultMapTypeId: 'hybrid', gestureHandling: 'cooperative', defaultZoom: zoom};
    const selected = markers?.find(x => x.selected);
    if (center && selected && selected.latitude) {
        result = {...result, center: {lat: selected.latitude, lng: selected.longitude}}
    } else if (markers?.length === 0) {
        result = {
            ...result,
            center: defaultMapCenter(markers, client),
            defaultZoom: 4
        }
    }
    return result;
};

export const fitBounds = (map, markers = [], callback = () => {
}) => {
    const bounds = new google.maps.LatLngBounds();
    markers.forEach(m => {
        const myLatLng = new google.maps.LatLng(m.latitude, m.longitude);
        bounds.extend(myLatLng)
    });

    if (map && bounds) {
        map.fitBounds(bounds);
        callback();
    }
};

export const themeBodyColor = [
    {theme: 'cerulean', color: '#ffffff'},
    {theme: 'cosmo', color: '#ffffff'},
    {theme: 'cyborg', color: '#060606'},
    {theme: 'darkly', color: '#222222'},
    {theme: 'flatly', color: '#ffffff'},
    {theme: 'journal', color: '#ffffff'},
    {theme: 'lumen', color: '#ffffff'},
    {theme: 'paper', color: '#ffffff'},
    {theme: 'readable', color: '#ffffff'},
    {theme: 'sandstone', color: '#ffffff'},
    {theme: 'simplex', color: '#fcfcfc'},
    {theme: 'slate', color: '#272b30'},
    {theme: 'solar', color: '#002b36'},
    {theme: 'spacelab', color: '#ffffff'},
    {theme: 'superhero', color: '#2b3e50'},
    {theme: 'united', color: '#ffffff'},
    {theme: 'yeti', color: '#ffffff'},
]

export const colors = [
    //  Reds
    '#d50000',
    '#ff1744',
    '#ffebee',
    '#ffcdd2',
    '#e57373',
    //  Purples
    '#6200ea',
    '#d500f9',
    '#aa00ff',
    '#9575cd',
    '#ce93d8',
    '#d1c4e9',
    '#f3e5f5',
    //  Blues
    '#1a237e',
    '#0d47a1',
    '#2962ff',
    '#448aff',
    '#42a5f5',
    '#7986cb',
    '#8c9eff',
    '#c5cae9',
    //  Cyans-Teals
    '#004d40',
    '#006064',
    '#00b8d4',
    '#1de9b6',
    '#18ffff',
    '#a7ffeb',
    //  Greens
    '#1b5e20',
    '#388e3c',
    '#66bb6a',
    '#a5d6a7',
    //  Bright Greens
    '#00c853',
    '#00e676',
    '#69f0ae',
    '#b9f6ca',
    //  Light Greens
    '#64dd17',
    '#76ff03',
    '#b2ff59',
    '#ccff90',
    // Limes
    '#aeea00',
    '#c6ff00',
    '#eeff41',
    '#f4ff81',
    // Dark Limes
    '#827717',
    '#afb42b',
    '#d4e157',
    '#e6ee9c',
    // Yellows
    '#ffd600',
    '#ffea00',
    '#ffff00',
    '#ffff8d',
    // Oranges
    '#ff3d00',
    '#ff6e40',
    '#ff5722',
    '#ef6c00',
    '#ffa726',
    '#ffe0b2',
    //  Browns
    '#3e2723',
    '#5d4037',
    '#8d6e63',
    '#bcaaa4',
    '#efebe9',
    //  Greys
    '#1b1b25',
    '#212121',
    '#616161',
    '#9e9e9e',
    '#e0e0e0',
    '#fafafa',
    //  Blue Greys
    '#546e7a',
    '#78909c',
    '#b0bec5',


];


export const buildColorsCss = () => {
    let css = '';
    colors.forEach((c, i) => {
        css += `.color-picker.c${i}{
                    background: ${c};
                 }
                 .c${i}.abc-checkbox label::before {
                    border: 2px solid ${c}
                 }

                 .c${i}.abc-checkbox input[type="checkbox"]:checked + label::before {
                    background: ${c}
                 }`
    });
    return css;
};

export function colorFor(userId) {
    if (!userId) {
        return '#3a87ad';
    }
    return colors[userId % colors.length]
}

export function dashboardStatuses() {
    return [
        {label: 'In Draft', value: 'draft', color: '#929292'},
        {label: 'Sent', value: 'proposed', color: '#008915'},
        // {label: 'Accepted', value: 'accepted', color: '#79c44f'},
        // {label: 'Promised', value: 'promised', color: '#ffea00'},
        {label: 'Backlog', value: 'backlog', color: '#0077D7'},
        // {label: 'Scheduled', value: 'scheduled', color: '#0006ff'},
        {label: 'Completed', value: 'completed', color: '#FF5707'},
        {label: 'Invoiced', value: 'invoiced', color: '#E51D3C'},
        {label: 'Paid', value: 'paid', color: '#5E5E5E'},
    ]
}

export function schedulerEventStatuses() {
    return [
        {label: 'Confirmed', value: 'Confirmed', color: '#79c44f'},
        {label: 'Scheduled', value: 'Scheduled', color: '#0006ff'},
        {label: 'In Process', value: 'In Process', color: '#ec5d57'},
        {label: 'Completed', value: 'Completed', color: '#f38f1a'},
        {label: 'Missed', value: 'Missed', color: '#c82605'},
    ]
}

export function mapPersonSearch(data) {
    return data.map(x => {
        const start_date = x.start_date ? x.start_date : null;
        const termination_date = x.termination_date ? x.termination_date : null;

        return ({
            id: x.id,
            value: x.id,
            label: `${x.first_name} ${x.last_name}`,
            employee_id: x.employee_id,
            start_date: start_date,
            termination_date: termination_date,
            initials: x.initials,
            disabled: x.disabled,
            color: x.color,
            email: x.email
        })
    });
}

export function concat(array1, array2) {
    return [...new Set([...array1, ...array2])]
}

export const mapEvents = event => {
    const e = event.event_data;
    let title = `${!e.work_order_no ? e.name && `<b>${e.name}</b></br>` : `<b>WO #${e.work_order_no}
                   ${dollar(e.price)}</b> 
                   <b>${e.hours && e.hours.toString().length > 3 ? e.hours.toFixed(2) : e.hours}hrs</b><br/>`}<small>`;
    if (e.customer_name) {
        title += `${!e.location_address ? `<strong>${e.customer_name}</strong><br/>
                       ${e.site_name}
                       ${e.site_street}</br>
                       ${e.site_city}
                       ${e.site_state}
                       ${e.site_zip}
                       </small><br/>` : `<strong>${e.customer_name}</strong><br/>${e.location_address}</small><br/>`}`
    }
    title += `<strong>${e.source_name || e.person_name}</strong>`;
    let timeDiff = moment.duration(moment(event.date_to).diff(moment(event.date_from)), 'milliseconds')

    return {
        ..._.omit(e, ['date_from', 'date_to']),
        id: event.id,
        start: moment(e.date_from).format(),
        end: moment(e.date_to).format(),
        timeDiff: timeDiff,
        allDay: e.all_day,
        status: event.status,
        proposal_no: event.proposal_no,
        proposal_id: event.proposal_id,
        event_no: event.event_no,
        title
    }
};

export const hhMM = (inputMinutes) => {
    let hours = Math.floor(inputMinutes / 60);
    let minutes = Math.floor(inputMinutes - (hours * 60));
    let H, M;
    if (hours < 10) H = ("0" + hours);
    if (minutes < 10) M = ("0" + minutes);
    return (H || hours) + ':' + (M || minutes);
};

export const round = (date, duration = moment.duration(15, "minutes"), method = 'ceil') => {
    return moment(Math[method]((+date) / (+duration)) * (+duration));
};

export const roundDecimal = (value) => {
    return Math.round(value * 100) / 100;
};

export const isEmpty = (x) => {
    return Object.keys(x).length === 0
};

export function swapElement(array, oldIndex, newIndex) {
    const tmp = array[oldIndex];
    array.splice(oldIndex, 1);
    array.splice(newIndex, 0, tmp);
}

export const rolesDescriptions = {
    owner:
        <ul className="text-left dropdown no-padding">
            <li>edit proposal service statuses</li>
        </ul>,
    admin:
        <ul className="text-left dropdown no-padding">
            <li>preview/print crew work orders</li>
            <li>create/edit new customers</li>
            <li>create edit proposals</li>
            <li>create/edit WO's</li>
            <li>create/edit scheduler</li>
            <li>create reports</li>
            <li>access to the admin panel</li>
        </ul>,
    sales_arborist:
        <ul className="text-left dropdown no-padding">
            <li>create/edit new customers</li>
            <li>create edit proposals</li>
            <li>create/edit WO's</li>
            <li>create/edit scheduler</li>
            <li>create reports</li>
        </ul>,
    scheduler:
        <ul className="text-left dropdown no-padding">
            <li>similar to sales arborist</li>
        </ul>,
    crew:
        <ul className="text-left dropdown no-padding">
            <li>print crew work orders</li>
            <li>report time track</li>
        </ul>,
    it_engineering:
        <ul className="text-left dropdown no-padding">
            <li>admin rights + super admin panel</li>
        </ul>,
    subcontractor:
        <ul className="text-left dropdown no-padding">
            <li>print crew work orders</li>
            <li>report time track</li>
        </ul>,
    technician:
        <ul className="text-left dropdown no-padding">
            <li>print crew work orders</li>
            <li>report time track</li>
            <li>send email to customer</li>
        </ul>,
}

export function RGBToHex(color) {
    const RR = ((color.r.toString(16).length === 1) ? "0" + color.r.toString(16) : color.r.toString(16))
    const GG = ((color.g.toString(16).length === 1) ? "0" + color.g.toString(16) : color.g.toString(16))
    const BB = ((color.b.toString(16).length === 1) ? "0" + color.b.toString(16) : color.b.toString(16))
    return "#" + RR + GG + BB;
}

const isHexColor = (color) => color.match(/^#([0-9a-f]{6})$/i);

const defaultHexColor = '#3A87AD';

export function hexToRGB(hex) {
    if (!isHexColor(hex)) {
        hex = defaultHexColor;
    }
    const m = hex.match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i);
    return {
        r: parseInt(m[1], 16),
        g: parseInt(m[2], 16),
        b: parseInt(m[3], 16)
    };
}

export function reverseColorArray(colors) {
    let reversedColors = colors.map(c => {
        if (c && c !== undefined) {
            const rgb = hexToRGB(c)
            let color
            const luminance = (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114) / 256;
            if (luminance <= 0.50) {
                color = '#fcfcfc'
            } else {
                color = '#444444'
            }
            return color
        }
    })
    return reversedColors
}

export function reverseColor(background) {
    if (typeof background === 'number') {
        background = colors[background] || '#3A87AD';
    }
    if (background && background !== undefined) {
        if (typeof background === 'number') {
            background = colors[background] || defaultHexColor;
        }
        const rgb = hexToRGB(background)
        let color
        const luminance = (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114) / 256;
        if (luminance <= 0.50) {
            color = '#fcfcfc'
        } else {
            color = '#444444'
        }
        return color
    }
}

export function formatTextToCapitalLetter(text) {
    text.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
}

export function getEmployeeInitialsAvatar(name, color) {
    const canvas = document.createElement('canvas');
    const backgroundColor = color ? colors[color] : '#3a87ad'
    canvas.style.display = 'none';
    canvas.width = '36';
    canvas.height = '36';
    document.body.appendChild(canvas);
    const context = canvas.getContext('2d');
    context.fillStyle = `${backgroundColor}`;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.font = "600 16px Courier";
    context.fillStyle = "#FFFFFF";
    if (name && name.length > 0) {
        const employeeName = name.split(" ")
        const firstName = employeeName[0].charAt(0)
        const lastName = employeeName[1].charAt(0)
        const initials = firstName + lastName

        context.fillText(initials.toUpperCase(), 8, 23);
        const data = canvas.toDataURL();
        document.body.removeChild(canvas);
        return data;
    } else {
        return false;
    }
}

export function convertFloatToHour(num) {
    let sign = num >= 0 ? 1 : -1;
    let min = 1 / 60;

    num = num * sign;

    let intpart = Math.floor(num);
    let decpart = num - intpart;

    decpart = min * Math.round(decpart / min);

    let minutes = Math.floor(decpart * 60);

    sign = sign == 1 ? '' : '-';

    return sign + `${intpart}.${minutes}`;
}

export function convertMillisToTime(millis) {
    const totalSeconds = Math.floor(millis / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Format the hours, minutes, and seconds as 'HH:mm:ss' format
    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    return formattedTime;
};

export const sortAssetsLabels = (a, b, field) => {
    const collator = new Intl.Collator("en", {numeric: true, sensitivity: "base",});
    return collator.compare(a[field].replace(/\s/g, ''), b[field].replace(/\s/g, ''))
}


export const checkIfEventsOverlap = (eventA, apiEventB) => {
    if (!eventA.all_day && !apiEventB.allDay && eventA.backgroundColor === apiEventB.backgroundColor &&
        eventA.event_id !== apiEventB.extendedProps.event_id &&
        apiEventB.end && apiEventB.start) {
        const eventAStart = new Date(eventA.start).getTime()
        const eventAEnd = new Date(eventA.end).getTime()
        const eventBStart = apiEventB.start.getTime()
        const eventBEnd = apiEventB.end.getTime()
        return ((eventAStart >= eventBStart && eventAStart < eventBEnd) ||
            (eventBStart >= eventAStart && eventBStart < eventAEnd))
    } else {
        return false;
    }
}

export const positionCalendarEventsInOrder = (events, calendarEventObjects) => {
    for (let calendarEventObj of calendarEventObjects) {
        const eventStartDay = calendarEventObj.start.getDate()

        const colorsPerDay = []

        events.map((e) => {
            if (colorsPerDay.indexOf(e.backgroundColor) === -1 &&
                !e.all_day &&
                (new Date(e.start).getDate() === eventStartDay)
            ) {
                colorsPerDay.push(e.backgroundColor)
            }
        })

        let overlappingEvents = events.filter((v) => checkIfEventsOverlap(v, calendarEventObj))
        if (overlappingEvents.length > 0) {
            overlappingEvents.push(calendarEventObj)
            overlappingEvents = overlappingEvents.map(e => e.event_id ? e.event_id : e.extendedProps.event_id)
        }

        if (colorsPerDay.indexOf(calendarEventObj.backgroundColor) !== -1) {
            let position = (100 / colorsPerDay.length) * colorsPerDay.sort().indexOf(calendarEventObj.backgroundColor)
            let width = 100 / colorsPerDay.length;

            const eventId = calendarEventObj.extendedProps.event_id;
            if (overlappingEvents.length > 0) {
                width = width / (overlappingEvents.length)
                position = position + ((100 / colorsPerDay.length) / overlappingEvents.length) * (overlappingEvents.sort().indexOf(eventId))
            }
            const elements = document.querySelectorAll(`[event_id="${eventId}"]`);

            elements.forEach(el => {
                $(el).css('width', `${width}%`)
                $(el).css('left', `${position}%`);
            })
        }
    }
}

export const isEmailValid = (value) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(value.trim());

export const updateEmbeddedLink = (string) => {
    let prodHttpLink = "https://embedded.treehub.me"
    let localHttpLink = "http://localhost:5000"

    if (string) {
        const primaryUrl = new URL(window.location.href)
        let primaryUrlOrigin = primaryUrl.origin

        if (primaryUrlOrigin.includes("localhost:3000")) {
            primaryUrlOrigin = localHttpLink
        } else if (primaryUrlOrigin.includes("app.treehub")) {
            primaryUrlOrigin = prodHttpLink
        } else {
            let hostnameParts = primaryUrl.hostname.split('.');
            hostnameParts[0] = "embedded-" + hostnameParts[0];
            primaryUrl.hostname = hostnameParts.join('.');
            primaryUrlOrigin = primaryUrl.origin
        }
        const httpLinksToReplace = ["https://embedded.treehub.me"];

        const regexHttpPattern = new RegExp(httpLinksToReplace.join('|'), 'g');
        string = string.replace(regexHttpPattern, primaryUrlOrigin);
    }

    return string
}

export const saveNewCsvDownload = (ref, fileName, params, source) => (dispatch, getState) => {
    const {auth} = getState()

    if (auth.download_notifications) {
        const colInfos = ref.store.colInfos
        const columns = []

        const colInfosKeys = Object.keys(colInfos)
        const headers = colInfosKeys.map(key => {
            if (!(colInfos[key].export === false || colInfos[key].hidden) || colInfos[key].export === true) {
                columns.push(key)
                const keyProps = ref.header?.props?.children?.filter(c => c.props.dataField === key)[0]
                const csvHeader = keyProps?.props?.csvHeader
                return csvHeader ? csvHeader : colInfos[key]?.name
            }
        }).filter(c => c)
        const csv = ref?.store?.data?.map(e => columns.map(key => {
            const keyProps = ref.header?.props?.children?.filter(c => c.props.dataField === key)[0]
            const format = keyProps?.props?.csvFormat
            return format ?
                `"${typeof format(e[key], e) === 'object' ?
                    format(e[key], e).length ?
                        format(e[key], e).join(',')
                        :
                        format(e[key], e)?.props?.amount
                    :
                    format(e[key], e)}"`
                :
                `"${e[key]}"`
        }))?.join("\n")
        const csvFile = headers + "\n" + csv
        const blob = new Blob([csvFile], {type: 'text/csv'});
        blob.name = fileName
        dataUpload(`/api/download_history`, blob, null, null, {
            params: JSON.stringify(params ? params : {}),
            source: source
        }, false)(dispatch, getState)
    }
}

export const equipmentAnswerTypes = (number, answerType, question, options, value, onChange = null, setAnswerOptions = null, preview = false, disabled = true, id = null) => {
    switch (answerType) {
        case 'text':
            return <TextAnswer
                number={number}
                question={question}
                value={value}
                onChange={onChange}
                options={options}
                setAnswerOptions={setAnswerOptions}
                preview={preview}
                disabled={disabled}
            />
        case 'checkbox':
            return <CheckboxAnswer
                number={number}
                question={question}
                value={value}
                onChange={onChange}
                options={options}
                setAnswerOptions={setAnswerOptions}
                preview={preview}
                disabled={disabled}
            />
        case 'switch':
            return <SwitchAnswer
                number={number}
                question={question}
                value={value}
                onChange={onChange}
                options={options}
                setAnswerOptions={setAnswerOptions}
                preview={preview}
                disabled={disabled}
                id={id}
            />
        case 'dates':
            return <DateAnswer
                number={number}
                question={question}
                value={value}
                onChange={onChange}
                options={options}
                setAnswerOptions={setAnswerOptions}
                preview={preview}
                disabled={disabled}
            />
        case 'files':
            return <FilesAnswer
                number={number}
                question={question}
                value={value}
                onChange={onChange}
                options={options}
                setAnswerOptions={setAnswerOptions}
                preview={preview}
                disabled={disabled}
                id={id}
            />
        case 'select':
            return <SelectAnswer
                number={number}
                question={question}
                value={value}
                onChange={onChange}
                options={options}
                setAnswerOptions={setAnswerOptions}
                preview={preview}
                disabled={disabled}
            />
        default:
            return <div></div>
    }
}

export const equipmentDefaultAnswer = (type) => {
    switch (type) {
        case 'text':
            return ''
        case 'checkbox':
            return []
        case 'switch':
            return []
        case 'dates':
            return {startDate: null, endDate: null}
        case 'files':
            return []
        case 'select':
            return []
        default:
            return null
    }
}

export const validateName = (name) => {
    let is_valid = true;
    let newError;
    if (name?.length === 0) {
        newError = "Name can't be empty";
        is_valid = false
    } else if (name?.length > 100) {
        newError = "Name can't be longer than 100 characters"
        is_valid = false
    }
    return {is_valid, errorMessage: newError}
}

export const validateDescription = (description) => {
    let is_valid = true;
    let newError;
    if (description?.length > 200) {
        newError = "Description can't be longer than 200 characters"
        is_valid = false
    }
    return {is_valid, errorMessage: newError}
}

export const checkRectangleCoordinates = (polygon) => {
    const pp = polygon.polygon_points
    return ((pp[0].lat || pp[0].latitude) === (pp[1].lat || pp[1].latitude) &&
        (pp[2].lat || pp[2].latitude) === (pp[3].lat || pp[3].latitude) &&
        (pp[0].lat || pp[0].latitude) !== (pp[2].lat || pp[2].latitude) &&
        (pp[0].lat || pp[0].latitude) !== (pp[3].lat || pp[3].latitude) &&
        (pp[0].lat || pp[1].latitude) !== (pp[2].lat || pp[2].latitude) &&
        (pp[0].lat || pp[1].latitude) !== (pp[3].lat || pp[3].latitude) &&
        (pp[0].lng || pp[0].longitude) === (pp[3].lng || pp[3].longitude) &&
        (pp[1].lng || pp[1].longitude) === (pp[2].lng || pp[2].longitude) &&
        (pp[0].lng || pp[0].longitude) !== (pp[1].lng || pp[1].longitude) &&
        (pp[0].lng || pp[0].longitude) !== (pp[2].lng || pp[2].longitude) &&
        (pp[3].lng || pp[3].longitude) !== (pp[2].lng || pp[2].longitude) &&
        (pp[3].lng || pp[3].longitude) !== (pp[1].lng || pp[1].longitude)
    );
}
export const renderPaginationPanel = (props, current_page = null, current_per_page = null) => {
    let page = current_page
    let per_page = current_per_page
    if (!page) {
        page = props.currPage
    }
    if (!per_page) {
        per_page = props.sizePerPage
    }

    return (
        <>
            <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                {props.components.sizePerPageDropdown}
            </div>
            <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6" style={{display: 'block'}}>
                <ul className="react-bootstrap-table-page-btns-ul pagination">
                    {props.totalPages > 5 && page !== 1 && page > 3 &&
                        <li className={"page-item " + `${page === 1 ? 'disabled' : 'pointer'}`} title="first page">
                        <span onClick={() => {
                            props.changePage(1, per_page)
                        }} className="page-link">&lt;&lt;</span>
                        </li>}
                    {props.totalPages > 1 && page !== 1 &&
                        <li className={"page-item " + `${page === 1 ? 'disabled' : 'pointer'}`} title="previous page">
                        <span onClick={() => {
                            props.changePage(page - 1, per_page)
                        }} className="page-link">&lt;</span>
                        </li>}
                    {page - 4 > 0 && page + 3 > props.totalPages &&
                        <li className={"page-item pointer"} title={page - 4}>
                        <span onClick={() => {
                            props.changePage(page - 4, per_page)
                        }} className="page-link">{page - 4}</span>
                        </li>}
                    {page - 3 > 0 && page + 2 > props.totalPages &&
                        <li className={"page-item pointer"} title={page - 3}>
                        <span onClick={() => {
                            props.changePage(page - 3, per_page)
                        }} className="page-link">{page - 3}</span>
                        </li>}
                    {page - 2 > 0 && <li className={"page-item pointer"} title={page - 2}>
                        <span onClick={() => {
                            props.changePage(page - 2, per_page)
                        }} className="page-link">{page - 2}</span>
                    </li>}
                    {page - 1 > 0 && <li className={"page-item pointer"} title={page - 1}>
                        <span onClick={() => {
                            props.changePage(page - 1, per_page)
                        }} className="page-link">{page - 1}</span>
                    </li>}
                    <li className="page-item active"><span className="page-link">{page}</span></li>
                    {page + 1 <= props.totalPages && <li className={"page-item pointer"} title={page + 1}>
                        <span onClick={() => {
                            props.changePage(page + 1, per_page)
                        }} className="page-link">{page + 1}</span>
                    </li>}
                    {page + 2 <= props.totalPages && <li className={"page-item pointer"} title={page + 2}>
                        <span onClick={() => {
                            props.changePage(page + 2, per_page)
                        }} className="page-link">{page + 2}</span>
                    </li>}
                    {page + 3 <= props.totalPages && page < 3 && <li className={"page-item pointer"} title={page + 3}>
                        <span onClick={() => {
                            props.changePage(page + 3, per_page)
                        }} className="page-link">{page + 3}</span>
                    </li>}
                    {page + 4 <= props.totalPages && page < 2 && <li className={"page-item pointer"} title={page + 4}>
                        <span onClick={() => {
                            props.changePage(page + 4, per_page)
                        }} className="page-link">{page + 4}</span>
                    </li>}
                    {props.totalPages > 1 && page !== props.totalPages &&
                        <li className={"page-item pointer"} title="next page">
                        <span onClick={() => {
                            props.changePage(page + 1, per_page)
                        }} className="page-link">&gt;</span>
                        </li>}
                    {props.totalPages > 5 && page !== props.totalPages && page < props.totalPages - 3 &&
                        <li className={"page-item " + `${page === props.totalPages ? 'disabled' : 'pointer'}`}
                            title="last page">
                        <span onClick={() => {
                            props.changePage(props.totalPages, per_page)
                        }} className="page-link">&gt;&gt;</span>
                        </li>}
                </ul>
            </div>
        </>
    );
}

export const updateExternalLinks = (type, string) => {
    let prodLink = "app.treehub.me"
    let betaLink = "beta.treehub.me"
    let testLink = "test.treehub.me"
    let uat1Link = "uat1.treehub.me"
    let uat2Link = "uat2.treehub.me"
    let localLink = "localhost:3000"

    if (string) {
        let primaryUrlHost = new URL(window.location.href).host
        let primaryUrlOrigin = new URL(window.location.href).origin

        if (type === "prod") {
            primaryUrlHost = "app.treehub.me"
            primaryUrlOrigin = "https://app.treehub.me"
        }

        if (primaryUrlOrigin.includes("localhost:3000")) {
            let prodHttpLink = "https://app.treehub.me"
            let betaHttpLink = "https://beta.treehub.me"
            let testHttpLink = "https://test.treehub.me"
            let uat1HttpLink = "https://uat1.treehub.me"
            let uat2HttpLink = "https://uat2.treehub.me"
            let localHttpLink = "http://localhost:3000"

            const httpLinksToReplace = [prodHttpLink, betaHttpLink, testHttpLink, localHttpLink, uat1HttpLink, uat2HttpLink];

            const regexHttpPattern = new RegExp(httpLinksToReplace.join('|'), 'g');
            string = string.replace(regexHttpPattern, primaryUrlOrigin);
        }

        const linksToReplace = [prodLink, betaLink, testLink, localLink, uat1Link, uat2Link];
        const regexPattern = new RegExp(linksToReplace.join('|'), 'g');
        string = string.replace(regexPattern, primaryUrlHost);
    }

    return string
}
