import React, {Component} from 'react';
import PropTypes from "prop-types"
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import Helmet from 'react-helmet'
import classNames from 'classnames'
import {addAlert} from '../App/actions'
import * as MyActions from '../../actions/printWorkOrders'
import {browserHistory} from 'react-router'
import {
    Button,
    Checkbox,
    Col,
    Form,
    FormControl,
    FormGroup,
    Grid, InputGroup,
    Panel,
    Radio,
    Row,
    Table,
    Well,
} from 'react-bootstrap'
import moment from "moment"

import Dollars from '../../components/Dollars'
import PageHeader from '../../components/PageTitle'
import squarePng from '../../images/square.png'
import StaticMap from "../PrintProposalPage/StaticMap"
import ProposalMenu from "../MapViewPage/new/ProposalMenu";
import PlantName from "../../components/PlantName";
import {sortAssetsLabels} from "../../common/commonHandlers";
import InputGroupAddon from "react-bootstrap/lib/InputGroupAddon";
import {downloadAllPdf} from "../../actions/printWorkOrders";
import MDSpinner from "react-md-spinner";
import './PrintWO.scss';

const Actions = {...MyActions, addAlert}
//NOTE: when printing we dont distinguish 3 types of assets


//NOTE: Page must be bootstrap xs={} for printing to not do weird things

const google = window.google;

const styles = {
    printWidget: {
        float: 'left',
        width: '175px',
        height: '120px',
        marginRight: '20px',
    },
    clearFix: {
        clear: 'both',
    },
}

class PrintWorkOrdersPage extends Component {

    state = {
        workOrders: [],
        se_data: this.props.location.query.scheduler_event_date_from && JSON.parse(this.props.location.query.scheduler_event_date_from),
        person_id: this.props.location.query.person_id && this.props.location.query.person_id,
        include_maps: {},
        showLogoHeader: false,
        logoHeight: null,
        logoPosition: "center",
        isPrintWindowOpened: false,
        blockPrint: true,
        use_wo_print_options: null,
        user_print_options: null,
        current_print_options: null,
        downloadInProgress: false,
    };

    componentDidMount() {
        this.props.actions.fetchCustomerInfo((client) =>{
            this.setState({use_wo_print_options: client.use_wo_print_options},()=>{
                const id = this.props.params.woIds;
                if (id) {
                    this.reload(id);
                }
            })
        })
    }

    makeDefault = () => {
        const print_options = this.state.current_print_options
        const current_user_id = this.props.user.user_id
        this.props.actions.updateUserWorkOrderPrintOptions(current_user_id, print_options, (response) => {
            this.setState({user_print_options: response.work_order_print_options})
        });
    }
    resetToDefault = () => {
        const user_print_options = this.state.user_print_options

        if(user_print_options){
            this.setState({
                current_print_options: user_print_options
            }, ()=>{
                if(this.state.use_wo_print_options){
                    this.props.actions.resetWorkOrderPrintOptions(this.props.params.woIds, user_print_options)
                }
            })
        }
    }

    woImg = (result) => {
        let workOrder = result;
        if (workOrder.site_hazards) {
            workOrder.site_hazards = workOrder.site_hazards.map(sh => {
                if (sh.image_order) {
                    let order = JSON.parse(sh.image_order);
                    sh.site_hazard_pics = sh.site_hazard_pics.map(img => {
                        img.order = order.findIndex((o) => o === img.id)
                        return img
                    })
                } else {
                    sh.site_hazard_pics = sh.site_hazard_pics.map((img, index) => {
                        img.order = index
                        return img
                    })
                }
                return sh
            })
        }
        if (workOrder.work_order_proposal_services) {
            workOrder.work_order_proposal_services = workOrder.work_order_proposal_services.map(wops => {
                wops.assets.map(a => {
                    a.asset_hazards.map(ah => {
                        if (ah.image_order) {
                            let order = JSON.parse(ah.image_order);
                            ah.asset_hazard_pics = ah.asset_hazard_pics.map(img => {
                                img.order = order.findIndex((o) => o === img.id)
                                return img
                            })
                        } else {
                            ah.asset_hazard_pics = ah.asset_hazard_pics.map((img, index) => {
                                img.order = index
                                return img
                            })
                        }
                        return ah
                    })
                    return a
                })
                return wops
            })
        }
        if (workOrder.work_order_proposal_services) {
            workOrder.work_order_proposal_services = workOrder.work_order_proposal_services.map(wops => {
                wops.assets.map(a => {
                    if (a.image_order) {
                        let order = JSON.parse(a.image_order);
                        a.photos = a.photos.map(img => {
                            img.order = order.findIndex((o) => o === img.id)
                            return img
                        })
                    } else {
                        a.photos = a.photos.map((img, index) => {
                            img.order = index
                            return img
                        })
                    }
                    return a
                })
                return wops
            })
        }
        if(result.print_options){
            workOrder.print_options = JSON.parse(result.print_options);
        }
        this.setState({workOrders: this.state.workOrders.concat(workOrder), logoHeight: workOrder.logo_height},
            ()=>{
                    if(this.state.use_wo_print_options){
                        this.setState({current_print_options: this.state.workOrders[0].print_options})
                    }
                    else{
                        this.props.actions.fetchUser(this.props.user.user_id, (user) => {
                            this.setState({current_print_options: user.work_order_print_options})
                        })
                    }
        });
    };

    reload(value) {
        this.setState({blockPrint: true})
        if (parseInt(value) > 0) {
            this.props.actions.getWorkOrder(value, result => {this.woImg(result); this.setState({blockPrint: false})})
        } else {
            this.props.actions.getWorkOrders({
                scheduler_event_date_from: this.state.se_data,
                person_id: this.state.person_id,
                locale: this.props.locale,
            }, results => {results.forEach(result => this.woImg(result)); this.setState({blockPrint: false})})
        }
    }

    handleFormChange = e => {
        let value = {};
        if (e.target.name === 'Plant Health Care' || e.target.name === 'Tree Care') {
            value = {format: e.target.name};
        } else {
            let checked = e.target.type === "checkbox"
                ? e.target.checked
                : e.target.value === "true";
            value = {
                [e.target.name]: checked,
            };
        }
        this.setState({current_print_options: {...this.state.current_print_options, ...value}})
        if(this.state.use_wo_print_options){
            this.props.actions.updateWorkOrderPrintOptions(this.props.params.woIds, value);
        }
    }

    handleSaveSettings = () => {
        const {logoHeight, logoPosition} = this.state
        this.props.actions.saveLogoSettings(
            this.state.workOrders[0].client_id, logoHeight, logoPosition)
    }

    renderPrintOptionsHeader = () => {
        const {workOrders, downloadInProgress} = this.state;
        const isMobile = window.screen.width <= 768
        const printOptions = this.state.current_print_options

        return (printOptions && <Panel
                bsStyle="primary"
                collapsible
                defaultExpanded={true}
                header="Print Options"
                className="hidden-print well-filter"
                style={{maxWidth: "100vw"}}
            >
                <Row>
                    <Col xs={12}>
                        <p>
                            The Work Order can be customized before printing. Checked items are
                            included in the printout.
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col className="options" xs={12}>
                        <Well bsSize="small">
                            <h5>Format</h5>
                            <FormGroup>
                                <Radio
                                    checked={printOptions.format === 'Plant Health Care'}
                                    onChange={this.handleFormChange}
                                    name={'Plant Health Care'}
                                    inline
                                >
                                    Plant Health Care
                                </Radio>
                                <br/>
                                <Radio
                                    checked={printOptions.format === 'Tree Care'}
                                    onChange={this.handleFormChange}
                                    name={'Tree Care'}
                                    inline
                                >
                                    Tree Care
                                </Radio>
                            </FormGroup>
                        </Well>
                        <Well bsSize="small">
                            <h5>Assets</h5>
                            <FormGroup>
                                <Checkbox
                                    name="locationDescription"
                                    checked={printOptions.locationDescription}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Location Description
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="dbh"
                                    checked={printOptions.dbh}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    DBH
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="treeHazards"
                                    checked={printOptions.treeHazards}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Tree Hazards
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="factors"
                                    checked={printOptions.factors}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Factors
                                </Checkbox>
                                <br/>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Notes</h5>
                            <FormGroup>
                                <Checkbox
                                    name="assetNotes"
                                    checked={printOptions.assetNotes}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Asset Notes
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="siteHazards"
                                    checked={printOptions.siteHazards}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Site Hazards
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="jsa"
                                    checked={printOptions.jsa}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    JSA
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="serviceNotes"
                                    checked={printOptions.serviceNotes}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Service Notes
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="workOrderNotes"
                                    checked={printOptions.workOrderNotes}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Work Order Notes
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="siteNotes"
                                    checked={printOptions.siteNotes}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Site Notes
                                </Checkbox>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Services</h5>
                            <FormGroup>
                                <Checkbox
                                    name="servicePriorities"
                                    checked={printOptions.servicePriorities}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Service Priorities
                                </Checkbox>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Maps</h5>
                            <FormGroup>
                                <Checkbox
                                    name="maps"
                                    checked={printOptions.maps}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    <div>
                                        <div>Maps</div>
                                        <Checkbox
                                            name="fitBounds"
                                            checked={printOptions.fitBounds}
                                            disabled={!printOptions.maps}
                                            onChange={this.handleFormChange}
                                            inline
                                        >
                                            Fit Bounds
                                        </Checkbox>
                                    </div>
                                    <Checkbox
                                        name="printMap"
                                        checked={printOptions.printMap}
                                        onChange={this.handleFormChange}
                                        inline
                                    >
                                        Print Main Map
                                    </Checkbox>
                                </Checkbox>
                                {workOrders.map(workOrder =>
                                    <div>
                                        {workOrder && workOrder.site_maps && workOrder.site_maps.map((sitemap, i) => (
                                                <div key={sitemap.id}>
                                                    <Checkbox
                                                        name={`maps_${sitemap.id}`}
                                                        checked={printOptions[`maps_${sitemap.id}`]}
                                                        onChange={this.handleFormChange}
                                                        inline
                                                    >
                                                        Sitemap {i + 1} #{workOrder.work_order_no}
                                                    </Checkbox>
                                                </div>
                                            )
                                        )}
                                        {workOrder && workOrder.site_maps && workOrder.site_maps.map((sitemap, i) => (
                                                <div key={sitemap.id}>
                                                    <Checkbox
                                                        name={`printMap${sitemap.id}`}
                                                        checked={printOptions[`printMap${sitemap.id}`]}
                                                        onChange={this.handleFormChange}
                                                        inline
                                                    >
                                                        Print Sitemap {i + 1} #{workOrder.work_order_no}
                                                    </Checkbox>
                                                </div>
                                            )
                                        )}
                                    </div>)}
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Prices</h5>
                            <FormGroup>
                                <Checkbox
                                    name="proposalTotal"
                                    checked={printOptions.proposalTotal}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Proposal Total
                                </Checkbox>

                                <br/>
                                <Checkbox
                                    name="priceEachService"
                                    checked={printOptions.priceEachService}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Price for each Service
                                </Checkbox>
                            </FormGroup>
                        </Well>

                        <Well bsSize="small">
                            <h5>Options</h5>
                            <FormGroup>
                                <Checkbox
                                    name="largeAssetPhotos"
                                    checked={printOptions.largeAssetPhotos}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Large Asset Photos
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="estimatedHours"
                                    checked={printOptions.estimatedHours}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Estimated Hours
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="promisedDates"
                                    checked={printOptions.promisedDates}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Promised Dates
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="showLogo"
                                    checked={printOptions.showLogo}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Show Company Logo
                                </Checkbox>
                                <br/>
                                <Checkbox
                                    name="showPageNumbers"
                                    checked={printOptions.showPageNumbers}
                                    onChange={this.handleFormChange}
                                    inline
                                >
                                    Show Page Numbers
                                </Checkbox>
                            </FormGroup>
                        </Well>
                        {printOptions.showLogo &&
                            <Well bsSize="small" style={styles.printWidget} className="panel-boxes">
                                <h5>Logo options</h5>
                                <FormGroup>
                                    <FormControl
                                        name="logoHeight"
                                        type="text"
                                        placeholder="Height"
                                        value={this.state.logoHeight ? this.state.logoHeight : ''}
                                        onChange={e => this.setState({logoHeight: e.target.value})}
                                        onBlur={this.handleSaveSettings}
                                    />
                                    <Radio
                                        name="logoPosition"
                                        checked={this.state.logoPosition === 'left'}
                                        onChange={e => this.setState({[e.target.name]: e.target.value}, () => this.handleSaveSettings())}
                                        value={'left'}
                                        inline
                                    >Left</Radio>
                                    <br/>
                                    <Radio
                                        name="logoPosition"
                                        checked={this.state.logoPosition === 'center'}
                                        onChange={e => this.setState({[e.target.name]: e.target.value}, () => this.handleSaveSettings())}
                                        value={'center'}
                                        inline
                                    >Center</Radio>
                                    <br/>
                                    <Radio
                                        name="logoPosition"
                                        checked={this.state.logoPosition === 'right'}
                                        onChange={e => this.setState({[e.target.name]: e.target.value}, () => this.handleSaveSettings())}
                                        value={'right'}
                                        inline
                                    >Right</Radio>
                                    <br/>
                                </FormGroup>
                            </Well>}
                    </Col>
                </Row>
                <Row>
                    <div className="hright flex-wrap mr-10">
                        <Button
                            bsSize="small"
                            onClick={() => this.resetToDefault()}
                            bsStyle="primary"
                            className='ml-6 mt-3'
                        >
                            Reset to Default
                        </Button>
                        <br/>
                        <Button
                            bsSize="small"
                            onClick={() => this.makeDefault()}
                            bsStyle="primary"
                            className='ml-6 mt-3'
                        >
                            Make Default
                        </Button>
                        <br/>
                        <Button
                            bsSize="small"
                            onClick={() => {
                                if (this.state.use_wo_print_options) {
                                    this.setState({downloadInProgress: true}, () => this.props.actions.downloadPdf(workOrders[0],
                                        () => this.setState({downloadInProgress: false}),
                                        () => this.setState({downloadInProgress: false})
                                    ))
                                }
                                if (this.props.params.woIds === 'all') {
                                    this.setState({downloadInProgress: true}, () => this.props.actions.updateAllWorkOrderPrintOptions({ scheduler_event_date_from: this.state.se_data, person_id: this.state.person_id }, this.state.current_print_options, () => {
                                        this.props.actions.downloadAllPdf({scheduler_event_date_from: this.state.se_data, person_id: this.state.person_id, locale: this.props.locale}, workOrders[0].person_full_name,
                                            () => this.setState({downloadInProgress: false}),
                                            () => this.setState({downloadInProgress: false})
                                        )
                                    }))
                                } else {
                                    this.setState({downloadInProgress: true}, () => this.props.actions.updateWorkOrderPrintOptions(this.props.params.woIds, this.state.current_print_options, () => {
                                        this.props.actions.downloadPdf(workOrders[0],
                                            () => this.setState({downloadInProgress: false}),
                                            () => this.setState({downloadInProgress: false})
                                        )
                                    }))
                                }
                            }}
                            bsStyle="primary"
                            className='ml-6 mt-3'
                            disabled={this.state.blockPrint || downloadInProgress}
                        >
                            {downloadInProgress ? <div><MDSpinner size={20} className="mr-5"/>Processing...</div> : 'Download PDF'}
                        </Button>
                        <br/>
                        <Button
                            bsSize="small"
                            onClick={() => {
                                this.setState({downloadInProgress: true}, () => this.props.actions.printPdf(workOrders[0],
                                    () => this.setState({downloadInProgress: false}),
                                    () => this.setState({downloadInProgress: false})
                                ))
                            }}
                            bsStyle="primary"
                            className='ml-6 mt-3'
                            disabled={this.state.blockPrint || downloadInProgress}
                        >
                            Print
                        </Button>
                    </div>
                </Row>
            </Panel>
        )
    }
    renderApplicationSites = workOrder => {
        if (
            workOrder.application_sites === undefined ||
            workOrder.application_sites.length === 0
        ) {
            return null
        }

        return (
            <Row>
                <Col xs={12}>
                    <strong>Application Sites:</strong>{' '}
                    {workOrder.application_sites
                        .map(a => `${a.name}: ${a.description}`)
                        .join(', ')}
                </Col>
            </Row>
        )
    }
    handleRender = () => {
        this.rendered++;
        const {mapsToRender} = this.state;

        if (mapsToRender === this.rendered) {
            setTimeout(() => {
                window.status = 'rendered';
            }, 1000);
        }
    };

    renderSiteMap = (workOrder, mapNum, client, options) => {
        if (workOrder.work_order_proposal_services === undefined || (options && !options.maps)) {
            return null
        }
        return (options.maps && <div
            className={classNames('print_site_map', 'avoid_page_breaking_inside', 'map-print', {'hidden-print': (options && !options.printMap)})}>
            <h5>
                Site Map {mapNum}
            </h5>
            <StaticMap
                size="600x600"
                maptype={workOrder.site_map_type || "hybrid"}
                workOrderToken={workOrder.wo_token}
                zoom={(options && !options.fitBounds) ? (workOrder.site_zoom ? +workOrder.site_zoom : 17) : 17}
                center={(options && !options.fitBounds) && `${+workOrder.site_latitude || ((client && client.organization_latitude) ?
                    client.organization_latitude
                    : 40.0)},
            ${+workOrder.site_longitude || ((client && client.organization_longitude) ?
                    client.organization_longitude
                    : -105.0)}`}
                mainMap={true}
                onRender={() => this.handleRender()}
                fitBounds={options && options.fitBounds}
            />
        </div>)
    }

    _renderMapSettings = (workOrder, options) => {
        const siteMaps = workOrder.site_maps
        if (!!(siteMaps && siteMaps.length > 0)) {
            return siteMaps?.map((siteMap, index) =>
                options[`maps_${siteMap.id}`] && <div key={siteMap.id + "_settings"}
                                                      className={classNames('print_site_map', 'top15', 'avoid_page_breaking_inside', {'hidden-print': !options[`printMap${siteMap.id}`]})}>
                    {this._renderSiteMap(siteMap, workOrder, index, this.props.client)}
                </div>)
        }
    }
    _renderSiteMap = (siteMap, workOrder, idx, client) => {
        if (workOrder.work_order_proposal_services === undefined) {
            return null
        }
        return <div className="map-print">
            <StaticMap
                size="600x600"
                zoom={siteMap.default_zoom || 20}
                maptype={siteMap.map_type || 'hybrid'}
                center={`${siteMap.latitude || ((client && client.organization_latitude) ?
                    client.organization_latitude
                    : 40.0)},
                ${siteMap.longitude || ((client && client.organization_longitude) ?
                    client.organization_longitude
                    : -105.0)}`
                }

                workOrderToken={workOrder.wo_token}
                onRender={() => this.handleRender()}
                mainMap={false}
            />
        </div>
    }
    renderPhotos = workOrder => {
        if (workOrder.work_order_proposal_services === undefined) {
            return null
        }
        const isMobile = window.screen.width <= 425;

        let photos = []

        workOrder.work_order_proposal_services.forEach(wops =>
            !wops.is_bundle_service ?
                wops.assets.sort((a, b) => sortAssetsLabels(a, b, 'asset_label')).forEach(a => {
                    a.photos.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).forEach(p => {
                        photos.push(
                            <div className="photo">
                                <div className="photo-image" style={{padding: isMobile && 0}}>
                                    <img alt="tree" src={p.url}/>
                                </div>
                                <div className="text-center photo-text">
                                    #{a.asset_label}{" "}<PlantName plant={a.name}/>
                                </div>
                            </div>
                        )
                    })
                })
                :
                wops.bundle_services.sort((a, b) => a.service_number - b.service_number).forEach(bs =>
                    bs.assets.sort((a, b) => sortAssetsLabels(a, b, 'asset_label')).forEach(a => {
                        a.photos.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).forEach(p => {
                            photos.push(
                                <div className="photo">
                                    <div className="photo-image" style={{padding: isMobile && 0}}>
                                        <img alt="tree" src={p.url}/>
                                    </div>
                                    <div className="text-center photo-text">
                                        #{a.asset_label}{" "}<PlantName plant={a.name}/>
                                    </div>
                                </div>
                            )
                        })
                    })
                )
        )

        if (photos.length === 0) {
            photos = <p>No Asset Photos Exist</p>
        }

        return (
            <Row className="avoid_page_breaking_inside">
                <Col xs={12}>
                    <h5>Photos</h5>
                    {photos}
                </Col>
            </Row>
        )
    }
    renderHazardPhotos = workOrder => {
        if (workOrder.work_order_proposal_services === undefined) {
            return null
        }
        const isMobile = window.screen.width <= 425;

        let asset_hazard_pics = []

        workOrder.work_order_proposal_services.forEach(wops =>
            !wops.is_bundle_service ?
                wops.assets.sort((a, b) => sortAssetsLabels(a, b, 'asset_label')).forEach(a => {
                    a.asset_hazards.forEach(ah => {
                        ah.asset_hazard_pics.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).forEach(hp => {
                            asset_hazard_pics.push(
                                <Table bordered key={hp}>
                                    <thead/>
                                    <tbody>
                                    <tr>
                                        <td className="text-center">
                                            <img width={isMobile && window.screen.width - 60} alt="tree" src={hp.url}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-center">
                                            <h6 className="text-danger">Tree Hazard for:
                                                #{a.asset_label} <PlantName plant={a.name}/>
                                            </h6>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            )
                        })
                    })
                })
                :
                wops.bundle_services.sort((a, b) => a.service_number - b.service_number).forEach(bs =>
                    bs.assets.sort((a, b) => sortAssetsLabels(a, b, 'asset_label')).forEach(a => {
                        a.asset_hazards.forEach(ah => {
                            ah.asset_hazard_pics.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).forEach(hp => {
                                asset_hazard_pics.push(
                                    <Table bordered key={hp}>
                                        <thead/>
                                        <tbody>
                                        <tr>
                                            <td className="text-center">
                                                <img width={isMobile && window.screen.width - 60} alt="tree" src={hp.url}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center">
                                                <h6 className="text-danger">Tree Hazard for:
                                                    #{a.asset_label} <PlantName plant={a.name}/>
                                                </h6>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                )
                            })
                        })
                    })
                )
        )

        if (asset_hazard_pics.length === 0) {
            asset_hazard_pics = <p>No Hazard Photos Exist</p>
        }
        return (
            <Row className="avoid_page_breaking_inside">
                <Col xs={12}>
                    <h5>Photos</h5>
                    {asset_hazard_pics}
                </Col>
            </Row>
        )
    }
    renderSiteHazardPhotos = workOrder => {
        if (workOrder.work_order_proposal_services === undefined) {
            return null
        }
        const isMobile = window.screen.width <= 425;

        let site_hazard_pics = []

        workOrder.site_hazards.forEach(sh => {
            sh.site_hazard_pics.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).forEach(p => {
                site_hazard_pics.push(
                    <Table bordered key={p}>
                        <thead/>
                        <tbody>
                        <tr>
                            <td className="text-center">
                                <img width={isMobile && window.screen.width - 60} alt="tree" src={p.url}/>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">
                                <h6 className="text-danger">Site Hazard:
                                    {" "}{workOrder.site_full_address}
                                </h6>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                )
            })
        })

        if (site_hazard_pics.length === 0) {
            site_hazard_pics = <p>No Hazard Photos Exist</p>
        }
        return (
            <Row className="avoid_page_breaking_inside">
                <Col xs={12}>
                    <h5>Photos</h5>
                    {site_hazard_pics}
                </Col>
            </Row>
        )
    }
    renderConsumables = workOrder => {
        if (
            workOrder.consumables === undefined ||
            workOrder.consumables.length === 0
        ) {
            return null
        }
        return (
            <Row className="avoid_page_breaking_inside">
                <Col xs={12}>
                    <strong>Consumables</strong>: {workOrder.consumables.join(', ')}
                </Col>
            </Row>
        )
    }
    renderServices = (workOrder, options) => {
        const isMobile = window.screen.width <= 435;
        return (
            <div className={isMobile ? "fontSize8 ml-15" : "ml-15"}>
                <br/>
                <Row>
                    <Col xs={1}>
                        <h6>Svc #</h6>
                    </Col>
                    <Col md={4} xs={3}>
                        <h6>Service Description</h6>
                    </Col>
                    <Col md={3} xs={2}>
                        <h6>Asset(s)</h6>
                    </Col>
                    <Col xs={1}>
                        {options && options.estimatedHours ? <h6>Est. Hrs.</h6> : null}
                    </Col>
                    <Col xs={1} className='actual-hrs-col'>
                        <h6>Actual Hrs.</h6>
                    </Col>
                    <Col xs={1} className='price-col'>
                        {options && options.priceEachService ? <h6>Price</h6> : null}
                    </Col>
                    <Col xs={1}  className='completed-col'>
                        <h6>Completed</h6>
                    </Col>
                </Row>

                {workOrder.work_order_proposal_services &&
                    workOrder.work_order_proposal_services
                        .slice()
                        .filter((r) => r.removal_service !== true)
                        .sort((a, b) => a.service_no - b.service_no)
                        .map(ps => {
                            if (!ps.is_bundle_service) {
                                return ps.assets.sort((a, b) => sortAssetsLabels(a, b, 'asset_label')).map((a, idx) => {
                                    return (
                                        <div>
                                            <Row className="avoid_page_breaking_inside">
                                                <Col xs={1}>
                                                    {idx === 0 ? ps.order_number_in_proposal : null}
                                                </Col>
                                                <Col md={4} xs={3} className="preserve-text-area-format">
                                                    <div style={{display: idx === 0 ? 'block' : 'none'}}>
                                                        <strong>{ps.service_name}</strong>{' '}
                                                        <h6 className="text-danger">
                                                            {
                                                                options && options.promisedDates ? `Promised:
                              ${ps.promised_date_start ? moment(ps.promised_date_start).format('L') : ""} - ${ps.promised_date_end ? moment(ps.promised_date_end).format('L') : ""}`
                                                                    : null
                                                            }
                                                        </h6>
                                                        <strong>
                                                            {options && options.servicePriorities && ps.priorities && ps.priorities.length > 0 ? 'Priority: ' : null}
                                                            {options && options.servicePriorities && ps.priorities && ps.priorities.length > 0 &&
                                                                ps.priorities.map(p => <span
                                                                    key={p.id}>{p.name}{ps.priorities.length > 1 ? ', ' : ' '}</span>)}
                                                        </strong>
                                                        <br/>
                                                        {options && options.serviceNotes &&
                                                            ps.proposal_service_note}
                                                        <br/>
                                                        {options && (options.serviceNotes && options.workOrderNotes) &&
                                                            <br/>}
                                                        {options && options.workOrderNotes &&
                                                        ps.ps_work_order_note && ps.ps_work_order_note !== null
                                                            ? <div className='columnDirection'>
                                                                <span>WO Note:</span>
                                                                {ps.ps_work_order_note}
                                                            </div>
                                                            : null}
                                                    </div>
                                                </Col>

                                                <Col md={3} xs={2}>
                                                    <p>
                                                        <strong>
                                                            #{a.asset_label} <PlantName plant={a.name}/>
                                                        </strong>
                                                        {a.plant_count > 1 ? ` [${a.plant_count}]` : null}
                                                        {options && options.dbh && a.dbh ? ` DBH: ${a.dbh}"` : null}
                                                        {a.stems ? <br/> : null}
                                                        {a.stems ? ` Stems: ${a.stems}` : null}
                                                        {options && options.factors && a.factors ? <br/> : null}
                                                        {options && options.factors && a.factors ? ` Factors: ${a.factors.map(f => ' ' + f.name)}` : null}
                                                        {options && options.locationDescription ? <br/> : null}
                                                        {options && options.locationDescription && (options.locationDescription !== 'null' || options.locationDescription.length > 0)
                                                            ? `Location: ${a.location}`
                                                            : null}
                                                        {options && options.locationDescription ? <br/> : null}
                                                        {options && options.assetNotes && a.note && a.note.length > 1
                                                            ? `Note: ${a.note}`
                                                            : null}
                                                        {options && options.assetNotes ? <br/> : null}
                                                        <h5 className="text-warning">
                                                            <strong>
                                                                {options && options.treeHazards && a.asset_hazards && a.asset_hazards.length >= 1
                                                                    ? `HAZARD: ${a.asset_hazards.map(ah => {
                                                                        return (
                                                                            `${ah.asset_hazard}`
                                                                        )
                                                                    })}`
                                                                    : null}
                                                            </strong>
                                                        </h5>
                                                    </p>
                                                </Col>

                                                <Col xs={1}>
                                                    {idx === 0
                                                        ? options && options.estimatedHours ? ps.est_hours : null
                                                        : null}
                                                </Col>

                                                <Col xs={1} className='actual-hrs-col'>
                                                    <img
                                                        alt="checkbox"
                                                        src={squarePng}
                                                        style={{height: isMobile ? 10 : 20, width: isMobile ? 20 : 40}}
                                                    />
                                                </Col>

                                                <Col xs={1} className="assetRow price-col">
                                                    {idx === 0
                                                        ? options && options.priceEachService && ps.price
                                                            ? <Dollars amount={ps.price}/>
                                                            : null
                                                        : null}
                                                </Col>

                                                <Col xs={1} className='completed-col'>
                                                    <img
                                                        alt="checkbox"
                                                        src={squarePng}
                                                        style={{height: isMobile ? 10 : 20, width: isMobile ? 10 : 20}}
                                                    />
                                                </Col>

                                            </Row>
                                            <p>&nbsp;</p>
                                        </div>
                                    )
                                })
                            } else {
                                return <>
                                    <div>
                                        <Row className="avoid_page_breaking_inside">
                                            <Col xs={1}>
                                                {ps.order_number_in_proposal}
                                            </Col>
                                            <Col md={4} xs={3} className="preserve-text-area-format">
                                                <div style={{display: 'block'}}>
                                                    <strong>{ps.service_name}</strong>{' '}
                                                    <h6 className="text-danger">
                                                        {
                                                            options && options.promisedDates ? `Promised:
                              ${ps.promised_date_start ? moment(ps.promised_date_start).format('L') : ""} - ${ps.promised_date_end ? moment(ps.promised_date_end).format('L') : ""}`
                                                                : null
                                                        }
                                                    </h6>
                                                    <strong>
                                                        {options && options.servicePriorities && ps.priorities && ps.priorities.length > 0 ? 'Priority: ' : null}
                                                        {options && options.servicePriorities && ps.priorities && ps.priorities.length > 0 &&
                                                            ps.priorities.map(p => <span
                                                                key={p.id}>{p.name}{ps.priorities.length > 1 ? ', ' : ' '}</span>)}
                                                    </strong>
                                                    <br/>
                                                    {options && options.serviceNotes &&
                                                        ps.proposal_service_note}
                                                    <br/>
                                                    {options && (options.workOrderNotes && options.workOrderNotes) &&
                                                        <br/>}
                                                    {options && options.workOrderNotes &&
                                                    ps.ps_work_order_note && ps.ps_work_order_note !== null
                                                        ? <div className='columnDirection'>
                                                            <span>WO Note:</span>
                                                            {ps.ps_work_order_note}
                                                        </div>
                                                        : null}
                                                </div>
                                            </Col>

                                            <Col md={3} xs={2}>
                                            </Col>

                                            <Col xs={1}>
                                                {options && options.estimatedHours ? ps.est_hours : null}
                                            </Col>

                                            <Col xs={1}>
                                                <img
                                                    alt="checkbox"
                                                    src={squarePng}
                                                    style={{height: isMobile ? 10 : 20, width: isMobile ? 20 : 40}}
                                                />
                                            </Col>

                                            <Col xs={1} className="assetRow">
                                                {options && options.priceEachService && ps.price
                                                    ? <Dollars amount={ps.price}/>
                                                    : null}
                                            </Col>

                                            <Col xs={1}>
                                                <img
                                                    alt="checkbox"
                                                    src={squarePng}
                                                    style={{height: isMobile ? 10 : 20, width: isMobile ? 10 : 20}}
                                                />
                                            </Col>

                                        </Row>
                                        <p>&nbsp;</p>
                                    </div>
                                    {
                                        ps.bundle_services.sort((a, b) => a.service_number - b.service_number).map((s) => {
                                            return s.assets.sort((a, b) => sortAssetsLabels(a, b, 'asset_label')).map((a, idx) => {
                                                return (
                                                    <div>
                                                        <Row className="avoid_page_breaking_inside">
                                                            <Col xs={1}>
                                                            </Col>
                                                            <Col md={4} xs={3} className="preserve-text-area-format">
                                                                <div style={{display: idx === 0 ? 'block' : 'none'}}>
                                                                    <strong>{s.service_name}</strong>{' '}
                                                                    <h6 className="text-danger">
                                                                        {
                                                                            options && options.promisedDates ? `Promised:
                        ${s.promised_date_start ? moment(s.promised_date_start).format('L') : ""} - ${s.promised_date_end ? moment(s.promised_date_end).format('L') : ""}`
                                                                                : null
                                                                        }
                                                                    </h6>
                                                                    <br/>
                                                                    {options && options.serviceNotes &&
                                                                        s.proposal_service_note}
                                                                    <br/>
                                                                    <br/>
                                                                    {options && s.ps_work_order_note && options.workOrderNotes &&
                                                                    s.ps_work_order_note && s.ps_work_order_note !== null
                                                                        ? <div>
                                                                            <span>WO Note:</span><br/>
                                                                            {s.ps_work_order_note}
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </Col>

                                                            <Col md={3} xs={2}>
                                                                <p>
                                                                    <strong>
                                                                        #{a.asset_label} <PlantName plant={a.name}/>
                                                                    </strong>
                                                                    {a.plant_count > 1 ? ` [${a.plant_count}]` : null}
                                                                    {options && options.dbh && a.dbh ? ` DBH: ${a.dbh}"` : null}
                                                                    <br/>
                                                                    {options && options.locationDescription && (options.locationDescription !== 'null' || options.locationDescription.length > 0)
                                                                        ? `Location: ${a.location}`
                                                                        : null}
                                                                    {options && options.locationDescription ?
                                                                        <br/> : null}
                                                                    {options && options.assetNotes && a.note && a.note.length > 1
                                                                        ? `Note: ${a.note}`
                                                                        : null}
                                                                    {options && options.assetNotes ? <br/> : null}
                                                                    <h5 className="text-warning">
                                                                        <strong>
                                                                            {options && options.treeHazards && a.asset_hazards && a.asset_hazards.length >= 1
                                                                                ? `HAZARD: ${a.asset_hazards.map(ah => {
                                                                                    return (
                                                                                        `${ah.asset_hazard}`
                                                                                    )
                                                                                })}`
                                                                                : null}
                                                                        </strong>
                                                                    </h5>
                                                                </p>
                                                            </Col>

                                                            <Col xs={1}>
                                                            </Col>

                                                            <Col xs={1}>
                                                                <img
                                                                    alt="checkbox"
                                                                    src={squarePng}
                                                                    style={{
                                                                        height: isMobile ? 10 : 20,
                                                                        width: isMobile ? 20 : 40
                                                                    }}
                                                                />
                                                            </Col>

                                                            <Col xs={1} className="assetRow">
                                                            </Col>

                                                            <Col xs={1}>
                                                                <img
                                                                    alt="checkbox"
                                                                    src={squarePng}
                                                                    style={{
                                                                        height: isMobile ? 10 : 20,
                                                                        width: isMobile ? 10 : 20
                                                                    }}
                                                                />
                                                            </Col>

                                                        </Row>
                                                        <p>&nbsp;</p>
                                                    </div>
                                                )
                                            })
                                        })
                                    }
                                </>
                            }
                        })}

            </div>
        )
    }
    renderRemovalServices = (workOrder, options) => {
        if (workOrder && workOrder.work_order_proposal_services && workOrder.work_order_proposal_services
            .filter((r) => r.removal_service === true).length < 1) {
            return null
        }
        return (
            <div className="removalServices text-large avoid_page_breaking_inside">
                <Panel>
                    <div className="removal-services-header text-center">REMOVAL SERVICES</div>
                    <br/>
                    {workOrder.work_order_proposal_services &&
                        workOrder.work_order_proposal_services
                            .slice()
                            .filter((r) => r.removal_service === true)
                            .sort((a, b) => a.service_no - b.service_no)
                            .map(ps => {
                                if (!ps.is_bundle_service) {
                                    return ps.assets.sort((a, b) => sortAssetsLabels(a, b, 'asset_label')).map((a, idx) => {
                                        return (
                                            <div>
                                                <Row className="avoid_page_breaking_inside">
                                                    <Col xs={1}>
                                                        <div className="removal-text">
                                                            {idx === 0 ? ps.order_number_in_proposal : null}
                                                        </div>
                                                    </Col>
                                                    <Col xs={4} className="preserve-text-area-format">
                                                        <div style={{display: idx === 0 ? 'block' : 'none'}}>
                                                            <div className="removal-text">{ps.service_name}</div>
                                                            {' '}
                                                            <h6 className="text-danger">
                                                                {
                                                                    options && options.promisedDates ? `Promised:
                              ${ps.promised_date_start ? moment(ps.promised_date_start).format('L') : ""} - ${ps.promised_date_end ? moment(ps.promised_date_end).format('L') : ""}`
                                                                        : null
                                                                }
                                                            </h6>
                                                            <strong>
                                                                {options && options.servicePriorities && ps.priorities && ps.priorities.length > 0 ? 'Priority: ' : null}
                                                                {options && options.servicePriorities && ps.priorities && ps.priorities.length > 0 &&
                                                                    ps.priorities.map(p => <span
                                                                        key={p.id}>{p.name}{ps.priorities.length > 1 ? ', ' : ' '}</span>)}
                                                            </strong>
                                                            <br/>
                                                            {options && options.serviceNotes &&
                                                                ps.proposal_service_note}
                                                            <br/>
                                                            {options && options.workOrderNotes &&
                                                            ps.ps_work_order_note && ps.ps_work_order_note.length > 1
                                                                ? `WO Note: ${ps.ps_work_order_note}`
                                                                : null}
                                                        </div>
                                                    </Col>

                                                    <Col xs={3}>
                                                        <p>
                                                            <div className="removal-text">
                                                                #{a.asset_label} <PlantName plant={a.name}/>
                                                            </div>
                                                            {a.plant_count > 1 ? ` [${a.plant_count}]` : null}
                                                            {options && options.dbh && a.dbh ? ` DBH: ${a.dbh}"` : null}
                                                            <br/>
                                                            {options && options.locationDescription && (options.locationDescription !== 'null' || options.locationDescription.length > 0)
                                                                ? `Location: ${a.location}`
                                                                : null}
                                                            {options && options.locationDescription ? <br/> : null}
                                                            {options && options.assetNotes && a.note && a.note.length > 1
                                                                ? `Note: ${a.note}`
                                                                : null}
                                                            {options && options.assetNotes ? <br/> : null}
                                                            <h5 className="text-warning">
                                                                <strong>
                                                                    {options && options.treeHazards && a.asset_hazards && a.asset_hazards.length >= 1
                                                                        ? `HAZARD: ${a.asset_hazards.map(ah => {
                                                                            return (
                                                                                `${ah.asset_hazard}`
                                                                            )
                                                                        })}`
                                                                        : null}
                                                                </strong>
                                                            </h5>
                                                        </p>
                                                    </Col>

                                                    <Col xs={1}>
                                                        {idx === 0
                                                            ? options && options.estimatedHours ? ps.est_hours : null
                                                            : null}
                                                    </Col>

                                                    <Col xs={1}>
                                                        <img
                                                            alt="checkbox"
                                                            src={squarePng}
                                                            style={{height: 20, width: 40}}
                                                        />
                                                    </Col>

                                                    <Col xs={1} className="assetRow">
                                                        {idx === 0
                                                            ? options && options.priceEachService && ps.price
                                                                ? <Dollars amount={ps.price}/>
                                                                : null
                                                            : null}
                                                    </Col>

                                                    <Col xs={1}>
                                                        <img
                                                            alt="checkbox"
                                                            src={squarePng}
                                                            style={{height: 20, width: 20}}
                                                        />
                                                    </Col>

                                                </Row>
                                                <p>&nbsp;</p>
                                            </div>
                                        )
                                    })
                                } else {
                                    return <>
                                        <div>
                                            <Row className="avoid_page_breaking_inside">
                                                <Col xs={1}>
                                                    <div className="removal-text">
                                                        {ps.order_number_in_proposal}
                                                    </div>
                                                </Col>
                                                <Col xs={4} className="preserve-text-area-format">
                                                    <div style={{display: 'block'}}>
                                                        <div className="removal-text">{ps.service_name}</div>
                                                        {' '}
                                                        <h6 className="text-danger">
                                                            {
                                                                options && options.promisedDates ? `Promised:
                              ${ps.promised_date_start ? moment(ps.promised_date_start).format('L') : ""} - ${ps.promised_date_end ? moment(ps.promised_date_end).format('L') : ""}`
                                                                    : null
                                                            }
                                                        </h6>
                                                        <strong>
                                                            {options && options.servicePriorities && ps.priorities && ps.priorities.length > 0 ? 'Priority: ' : null}
                                                            {options && options.servicePriorities && ps.priorities && ps.priorities.length > 0 &&
                                                                ps.priorities.map(p => <span
                                                                    key={p.id}>{p.name}{ps.priorities.length > 1 ? ', ' : ' '}</span>)}
                                                        </strong>
                                                        <br/>
                                                        {options && options.serviceNotes &&
                                                            ps.proposal_service_note}
                                                        <br/>
                                                        {options && options.workOrderNotes &&
                                                        ps.ps_work_order_note && ps.ps_work_order_note.length > 1
                                                            ? `WO Note: ${ps.ps_work_order_note}`
                                                            : null}
                                                    </div>
                                                </Col>

                                                <Col xs={3}>
                                                </Col>

                                                <Col xs={1}>
                                                    {options && options.estimatedHours ? ps.est_hours : null}
                                                </Col>

                                                <Col xs={1}>
                                                    <img
                                                        alt="checkbox"
                                                        src={squarePng}
                                                        style={{height: 20, width: 40}}
                                                    />
                                                </Col>

                                                <Col xs={1} className="assetRow">
                                                    {options && options.priceEachService && ps.price
                                                        ? <Dollars amount={ps.price}/>
                                                        : null}
                                                </Col>

                                                <Col xs={1}>
                                                    <img
                                                        alt="checkbox"
                                                        src={squarePng}
                                                        style={{height: 20, width: 20}}
                                                    />
                                                </Col>

                                            </Row>
                                            <p>&nbsp;</p>
                                        </div>
                                        {
                                            ps.bundle_services.sort((a, b) => a.service_number - b.service_number).map((s) => {
                                                return s.assets.sort((a, b) => sortAssetsLabels(a, b, 'asset_label')).map((a, idx) => {
                                                    return (
                                                        <div>
                                                            <Row className="avoid_page_breaking_inside">
                                                                <Col xs={1}>
                                                                </Col>
                                                                <Col xs={4} className="preserve-text-area-format">
                                                                    <div
                                                                        style={{display: idx === 0 ? 'block' : 'none'}}>
                                                                        <div
                                                                            className="removal-text">{s.service_name}</div>
                                                                        {' '}
                                                                        <h6 className="text-danger">
                                                                            {
                                                                                options && options.promisedDates ? `Promised:
                              ${s.promised_date_start ? moment(s.promised_date_start).format('L') : ""} - ${s.promised_date_end ? moment(s.promised_date_end).format('L') : ""}`
                                                                                    : null
                                                                            }
                                                                        </h6>

                                                                    </div>
                                                                </Col>

                                                                <Col xs={3}>
                                                                    <p>
                                                                        <div className="removal-text">
                                                                            #{a.asset_label} <PlantName plant={a.name}/>
                                                                        </div>
                                                                        {a.plant_count > 1 ? ` [${a.plant_count}]` : null}
                                                                        {options && options.dbh && a.dbh ? ` DBH: ${a.dbh}"` : null}
                                                                        <br/>
                                                                        {options && options.locationDescription && (options.locationDescription !== 'null' || options.locationDescription.length > 0)
                                                                            ? `Location: ${a.location}`
                                                                            : null}
                                                                        {options && options.locationDescription ?
                                                                            <br/> : null}
                                                                        {options && options.assetNotes && a.note && a.note.length > 1
                                                                            ? `Note: ${a.note}`
                                                                            : null}
                                                                        {options && options.assetNotes ? <br/> : null}
                                                                        <h5 className="text-warning">
                                                                            <strong>
                                                                                {options && options.treeHazards && a.asset_hazards && a.asset_hazards.length >= 1
                                                                                    ? `HAZARD: ${a.asset_hazards.map(ah => {
                                                                                        return (
                                                                                            `${ah.asset_hazard}`
                                                                                        )
                                                                                    })}`
                                                                                    : null}
                                                                            </strong>
                                                                            <br/>
                                                                            {options && options.serviceNotes &&
                                                                                s.proposal_service_note}
                                                                            <br/>
                                                                            {options && options.workOrderNotes &&
                                                                            s.ps_work_order_note && s.ps_work_order_note !== null
                                                                                ? <div className='columnDirection'>
                                                                                    <span>WO Note:</span>
                                                                                    {s.ps_work_order_note}
                                                                                </div>
                                                                                : null}
                                                                        </h5>
                                                                    </p>
                                                                </Col>

                                                                <Col xs={1}>
                                                                </Col>

                                                                <Col xs={1}>
                                                                    <img
                                                                        alt="checkbox"
                                                                        src={squarePng}
                                                                        style={{height: 20, width: 40}}
                                                                    />
                                                                </Col>

                                                                <Col xs={1} className="assetRow">
                                                                </Col>

                                                                <Col xs={1}>
                                                                    <img
                                                                        alt="checkbox"
                                                                        src={squarePng}
                                                                        style={{height: 20, width: 20}}
                                                                    />
                                                                </Col>

                                                            </Row>
                                                            <p>&nbsp;</p>
                                                        </div>

                                                    )
                                                })
                                            })
                                        }
                                    </>
                                }
                            })}

                </Panel>
            </div>
        )
    }
    renderSiblingServicesFromProposal = workOrder => {
        if (workOrder.work_order_proposal_services === undefined) {
            return null
        }

        const proposal_services_in_this_wo = workOrder.work_order_proposal_services.map(
            wops => wops.proposal_service_id
        )

        return (
            <Panel
                header="Other services from this proposal"
                className="avoid_page_breaking_inside"
            >
                {workOrder.sibling_services &&
                    workOrder.sibling_services
                        .filter(
                            ss =>
                                !proposal_services_in_this_wo.includes(ss.proposal_service_id)
                        )
                        .map(ss => (
                            <p key={ss.proposal_service_id}>
                                {ss.service_name} {ss.service_name}: {ss.status}
                            </p>
                        ))}
            </Panel>
        )
    }
    renderWoPageHeader = (workOrder, options) => {
        const isMobile = window.screen.width <= 425;
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <h5 className="text-warning">
                            <strong>
                                {options && options.jsa && workOrder.hazard_description && workOrder.hazard_description.length > 0
                                    ? `JSA: ${workOrder.hazard_description}`
                                    : null}
                            </strong>
                        </h5>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <h5 className="text-warning">
                            <strong>
                                {options && options.siteHazards && workOrder.site_hazards && workOrder.site_hazards.length >= 1
                                    ? `SITE HAZARDS: ${workOrder.site_hazards.map(sh => {
                                        return (
                                            ` ${sh.site_hazard}`
                                        )
                                    })}`
                                    : null}
                            </strong>
                        </h5>
                    </Col>
                </Row>
                <Row className={(!isMobile || this.state.isPrintWindowOpened) && "d-flex"}>
                    <Col md={4} xs={12}>
                        <h5 className="pad-left">
                            Work Order: {workOrder.work_order_no}
                        </h5>
                    </Col>

                    <Col md={4} xs={12}>
                        <h5 className="pad-left">
                            Proposal #{workOrder.proposal_no}
                        </h5>
                    </Col>

                    <Col md={4} xs={12} className="mobile-text-left">
                        <h5 className={(isMobile && this.state.isPrintWindowOpened) && "ml-15"}>
                            Sales Arborist: {workOrder.sales_arborist_name}
                        </h5>
                    </Col>
                </Row>
            </div>
        )
    }
    renderCustomerInfoHeader = workOrder => {
        const isMobile = window.screen.width <= 425;
        return (
            <Row className={(!isMobile || this.state.isPrintWindowOpened) && "d-flex"}>
                <Col md={4} xs={12}>
                    <div className="pad-left">
                        <strong>{workOrder.customer_name}</strong><br/>
                        {workOrder.customer_address_1}
                        <br/>
                        {workOrder.customer_address_2 ? workOrder.customer_address_2 : null}
                        {workOrder.customer_address_2 ? <br/> : null}
                        {workOrder.customer_city}, {workOrder.customer_state}{' '}
                        {workOrder.customer_zip}
                    </div>
                </Col>

                <Col md={4} xs={12}>
                    <div className="pad-left">
                        Site
                        Contact: <strong>{workOrder.site_contact_first_name} {workOrder.site_contact_last_name}</strong>
                        <br/>
                        Phone: {workOrder.customer_phone}
                        <br/>
                        Email: {workOrder.customer_email_address}
                    </div>
                </Col>

                <Col md={4} xs={12} className={(isMobile && this.state.isPrintWindowOpened) && "ml-15"}>
                    <strong>Site Details</strong>
                    <br/>
                    Name: {workOrder.site_name}
                    <br/>
                    {workOrder.site_full_address}
                </Col>
            </Row>
        )
    }
    renderWeatherConditions = () => {
        return (
            <Panel header="Weather Conditions" className="avoid_page_breaking_inside weather-condition-panel">
                <Table>
                    <thead>
                    <tr>
                        <th>Consumable</th>
                        <th>Quantity Used</th>
                        <th>Wind</th>
                        <th>Temperature</th>
                        <th>Record of App</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td colSpan="5">
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Panel>
        )
    }

    renderSiteNotes = workOrder => {
        return (
            <Row className="avoid_page_breaking_inside">
                <Col md={12} className="mt10">
                    <div className="pad-left">
                        <strong>Site Notes:</strong> {workOrder.site_notes}
                    </div>
                </Col>
            </Row>
        )
    }

    renderEquipment = workOrder => {
        const isMobile = window.screen.width <= 425;
        return (
            <Row className="avoid_page_breaking_inside">
                <Col xs={12} className={(!isMobile || this.state.isPrintWindowOpened) && "ml-15 mt10"}>
                    {(workOrder.equipment === undefined || workOrder.equipment.length === 0) ?
                        null :
                        <strong>Equipment: {workOrder.equipment.join(', ')}</strong>
                    }
                </Col>
            </Row>
        )
    }

    renderClientHeader = workOrder => {

        const isMobile = window.screen.width <= 768
        const logoPosition = this.state.logoPosition && this.state.logoPosition === 'center' ? 'unset' : this.state.logoPosition
        return (
            <Row>
                <Col md={12}>
                    <Row>
                        <Col xs={12} className="text-center">
                            <img
                                height={this.state.logoHeight && this.state.logoHeight}
                                style={{float: logoPosition}}
                                className="logoHeader"
                                width={isMobile && window.screen.width - 60}
                                alt="company header"
                                src={workOrder.client_header}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

    renderSchedule = workOrder => {
        const isMobile = window.screen.width <= 425;
        return (
            <Row className="avoid_page_breaking_inside">
                <Col xs={12}>
                    {workOrder && workOrder.events ?
                        <h5 className={(isMobile && this.state.isPrintWindowOpened) ? "ml-15 text-danger" : !isMobile && "ml-15 text-danger"}>Scheduled
                            for: {workOrder.events.map(e => (
                                <div className="floating-box">
                                    {`${e.employee === null ? "Any crew" : e.employee} on ${e.start_date}`}
                                </div>
                            ))
                            }</h5>
                        : null
                    }
                </Col>
            </Row>
        )
    }
    renderTotalStaffHours = workOrder => {
        if (workOrder.work_order_proposal_services === undefined) {
            return null
        }

        const total = workOrder.work_order_proposal_services.reduce(
            (total, wops) => {
                return total + (wops.est_hours === null ? 0 : wops.est_hours)
            },
            0
        )
        return (
            <Row className="avoid_page_breaking_inside">
                <Col xs={12} className="text-right mobile-text-left">
                    <strong>
                        Total Staff Hours: {total}
                    </strong>
                </Col>
            </Row>
        )
    }
    renderTotalPrice = workOrder => {
        if (workOrder.work_order_proposal_services === undefined) {
            return null
        }

        if (workOrder.work_order_proposal_services.filter(wops => wops.price).length === 0) {
            return null
        }
        const total = workOrder.work_order_proposal_services.reduce(
            (total, wops) => {
                return total + wops.price
            },
            0
        )

        return (
            <Row className="avoid_page_breaking_inside">
                <Col xs={12} className="text-right mobile-text-left">
                    <strong>
                        Total Price: {<Dollars amount={total}/>}
                    </strong>
                </Col>
            </Row>
        )
    }
    renderApplicatorNotes = () => {
        return (
            <Panel header="Applicator Notes" className="avoid_page_breaking_inside applicator-notes-panel">
                <table className="applicatorNotes">
                    <thead>
                    <tr>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <hr/>
                <Row  className="applicator-notes-summary">
                    <Col xs={3} sm={2}>
                        Applicators:
                    </Col>
                    <Col xs={2}>
                        Start
                    </Col>

                    <Col xs={2}>
                        Stop
                    </Col>

                    <Col xs={3} sm={4}>
                        Total Hours:
                        {' '}
                        <img
                            alt="hours box"
                            src={squarePng}
                            style={{height: 20, width: 60}}
                        />
                    </Col>

                    <Col xs={2}>
                        Equipment:
                    </Col>

                </Row>
                <p>&nbsp;</p>
                <Row>
                    <Col xs={12}>
                        <img
                            alt="checkbox"
                            src={squarePng}
                            style={{height: 20, width: 20}}
                        />
                        {' '}
                        STAY AWAY FROM THE TREATED TREES UNTIL DRY
                    </Col>
                </Row>
            </Panel>
        )
    }
    renderWorkOrder = workOrders => {
        const options = this.state.current_print_options

        return workOrders.map((workOrder, index) =>{
            return (
            <Row id="print-work-order" className="always_page_break_after" style={{maxWidth: "100vw"}} key={index}>
                <Col xs={12}>

                    {options && options.showLogo && this.renderClientHeader(workOrder)}

                    {this.renderSchedule(workOrder)}

                    {this.renderWoPageHeader(workOrder, options)}

                    {this.renderCustomerInfoHeader(workOrder)}

                    {options && options.siteNotes && this.renderSiteNotes(workOrder)}

                    {this.renderEquipment(workOrder)}

                    {options && options.estimatedHours && this.renderTotalStaffHours(workOrder)}

                    {options && options.proposalTotal && this.renderTotalPrice(workOrder)}

                    {this.renderServices(workOrder, options)}

                    {this.renderRemovalServices(workOrder, options)}

                    {this.renderSiblingServicesFromProposal(workOrder)}

                    {this.maybeRenderPhc(workOrder, options)}

                    {options && options.proposalTotal && this.renderTotalPrice(workOrder)}

                    {options && options.largeAssetPhotos && this.renderPhotos(workOrder)}
                    {options && options.largeAssetPhotos && this.renderHazardPhotos(workOrder)}
                    {options && options.largeAssetPhotos && this.renderSiteHazardPhotos(workOrder)}

                    {options && options.maps && this.renderSiteMap(workOrder, 1, this.props.client, options)}

                    {/* render map settings for work order */}
                    {options && options.maps && this._renderMapSettings(workOrder, options)}

                    <div className="hidden-print">
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <hr style={{borderTop: '2px dashed', color: 'white'}}/>
                    </div>
                </Col>
            </Row>
        )})
    }

    onSearch = e => {
        e.preventDefault()
        browserHistory.push('/print_work_orders/' + this.props.workOrderSearchText)
        //this.props.actions.findWorkOrder()
    }

    componentWillMount() {
        if (this.props.params.woIds) {
            console.info(
                'received work order ids via params:',
                this.props.params.woIds
            )
            this.props.actions.updateWorkOrderSearchText(this.props.params.woIds)
        }
        this.props.actions.findWorkOrder()
        this.props.actions.fetchUser(this.props.user.user_id, (user) => {
            this.setState({user_print_options: user.work_order_print_options})
        })
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps &&
            nextProps.params &&
            nextProps.params.woIds &&
            this.props.params.woIds !== nextProps.params.woIds
        ) {
            this.props.actions.findWorkOrder()
        }
        // based on new workordres fetch stiemaps accordingly
        if (
            nextProps &&
            nextProps.workOrders &&
            nextProps.workOrders.length > 0 &&
            this.props.workOrders.length == 0
        ) {
            let include_maps = {}
            nextProps.workOrders.map(workOrder => {
                const siteId = workOrder.site_id
                // include defalt site by default
                let mapInclude = {0: true}
                include_maps[workOrder.id] = mapInclude
            })
            const logoHeight = nextProps.workOrders && nextProps.workOrders.length > 0 && nextProps.workOrders[0].logo_height
            const logoPosition = nextProps.workOrders && nextProps.workOrders.length > 0 && nextProps.workOrders[0].logo_position
            const showLogoHeader = nextProps.workOrders && nextProps.workOrders.length > 0 && nextProps.workOrders[0].logo_visible

            this.setState({include_maps: include_maps, logoHeight, logoPosition, showLogoHeader})
            nextProps.workOrders.map(workOrder => {
                this.props.actions.fetchSiteMaps(workOrder.site_id)
            })
        }
        // based on customerSiteMaps set include maps flag
        if (
            nextProps &&
            nextProps.customerSiteMaps &&
            Object.keys(nextProps.customerSiteMaps).length > 0
        ) {
            let include_maps = {}
            const workOrders = this.props.workOrders || nextProps.workOrders
            workOrders.map(workOrder => {
                const siteId = workOrder.site_id
                const siteMaps = nextProps.customerSiteMaps[siteId]
                // include defalt site by default
                let mapInclude = {0: true}
                if (siteMaps) {
                    siteMaps.map(siteMap => {
                        mapInclude[siteMap.id] = siteMap.include_when_print
                    })
                }
                include_maps[workOrder.id] = mapInclude
            })
            this.setState({include_maps: include_maps})
        }
    }

    maybeRenderPhc(workOrder, options) {
        if (options && options.format.toString() !== 'Plant Health Care') {
            return null
        }
        return (
            <div className="avoid_page_breaking_inside">
                {this.renderApplicationSites(workOrder)}
                {this.renderConsumables(workOrder)}
                <p>&nbsp;</p>
                {this.renderWeatherConditions()}
                {this.renderApplicatorNotes()}
            </div>
        )
    }

    render() {
        const {workOrderSearchText, roles} = this.props
        const {workOrders} = this.state;
        return (
            <Grid id="print_work_order" fluid>
                <div className="hidden-print">
                    {roles && !(roles.every(r => r === 'crew' || r === 'subcontractor' || r === 'technician')) &&
                        <ProposalMenu
                            id={workOrders && workOrders[0] && workOrders[0].proposal_id}
                            expandButton={false}
                        />}
                </div>
                <Helmet title="Print WO's">
                    <style type="text/css">{`
            @page {
            size: 8.5in 11in;
            margin: 0.5in 0.25in 0.25in 0.25in;
            }
    `}</style>
                </Helmet>
                <div className="hidden-print">
                    <PageHeader pageName="Work Orders" pageDetail="Print work orders."/>
                </div>
                <Row className="hidden-print">
                    <Col xs={12}>
                        <Form inline onSubmit={e => this.onSearch(e)}>
                            <FormGroup bsSize="small">
                                <FormControl
                                    type="number"
                                    value={workOrderSearchText || ''}
                                    placeholder="Work Order No"
                                    onChange={e => this.props.actions.updateWorkOrderSearchText(e.target.value)}
                                />
                            </FormGroup>{' '}
                            <Button type="submit" bsSize="small" bsStyle="success"
                                    onClick={() => {
                                        workOrderSearchText && this.reload(workOrderSearchText)
                                        this.setState({workOrders: []})
                                    }}>
                                Search Work Order No.
                            </Button>
                        </Form>
                    </Col>
                </Row>

                <br/>

                {this.renderPrintOptionsHeader()}

                {this.renderWorkOrder(workOrders)}
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        roles: state.auth.roles,
        workOrderSearchText: state.workOrders.workOrderSearchText,
        workOrders: state.workOrders.workOrders,
        customerSiteMaps: state.siteMaps.siteMaps,
        client: state.client.customerInfo,
        user: state.auth,
        locale: state.userStatus.userStatus.locale,
    }
}

PrintWorkOrdersPage.propTypes = {
    workOrders: PropTypes.array.isRequired,
    workOrderSearchText: PropTypes.string.isRequired,
}

const mapDispatchToProps = dispatch => {
    return {actions: bindActionCreators(Actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintWorkOrdersPage)
